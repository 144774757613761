import React, { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../../../swipeableVideo.css";

import { ReactComponent as Play } from "../../../assets/Play.svg";

export default function SwipeableVideoSlider({ videos }) {
  const [isPlaying, setIsPlaying] = useState(Array(videos.length).fill(false));
  const videoRefs = useRef(videos.map(() => React.createRef()));

  const handleVideoClick = (index) => {
    const video = videoRefs.current[index].current;
    const newIsPlaying = [...isPlaying];

    if (video.paused) {
      video.play();
      newIsPlaying[index] = true;
    } else {
      video.pause();
      newIsPlaying[index] = false;
    }

    setIsPlaying(newIsPlaying);
  };

  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      navigation={true}
      modules={[Pagination, Navigation]}
      loop={true}
    >
      {videos.map((video, index) => (
        <SwiperSlide key={index}>
          <div className="relative">
            <div className="w-full h-[445px] shadow">
              <div
                style={{
                  overflow: "hidden",
                }}
                className="relative w-[70%] h-[445px] rounded-t-xl mx-auto"
              >
                <video
                  ref={videoRefs.current[index]}
                  onClick={() => handleVideoClick(index)}
                  className="w-full h-full object-cover rounded-xl"
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <source src={video.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                {!isPlaying[index] && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      cursor: "pointer",
                    }}
                    onClick={() => handleVideoClick(index)}
                  >
                    <Play className="h-20 w-20 lg:w-32 lg:h-32" />
                  </div>
                )}

                {/* <div className="absolute bottom-0 bg-white w-full py-2 rounded-tl-xl">
                  <div className="flex my-auto">
                    <img
                      src={video.img}
                      className="h-14 w-14 rounded-full mx-2"
                      alt="user"
                    />
                    <div>
                      <h4 className="font-DMsansSemiBold">{video.user}</h4>
                      <h4 className="font-DMsansRegular">{video.title}</h4>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
