import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ReactComponent as ShieldCheckIcon } from "../../assets/ShieldCheckIcon.svg";

export default function TermsModal({ open, setOpen, SubmitFunc }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div
          // dir={`${i18n.language == "ar" ? "rtl" : "ltr"}`}
          className={`flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0`}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-middle bg-white rounded-lg px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8  sm:max-w-2xl sm:w-full sm:p-6 ">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                  <ShieldCheckIcon
                    className="h-6 w-6 text-blue-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-5  sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className={`text-left text-lg leading-6 font-medium text-gray-900`}
                  >
                    {/* {t("contract")} */}
                  </Dialog.Title>
                  <div className="mt-5 max-h-96 overflow-auto">
                    <h1 className="text-center text-xl font-bold mb-7 ">
                      Telewee Content Partner Agreement
                    </h1>
                    <h1 className="mb-6 text-lg">
                      <div className="">
                        This Content Partner Agreement is effective as of
                        November 1st 2023
                      </div>
                    </h1>
                    <div>
                      This Contributor Agreement ("Agreement") governs the terms
                      by which you make your Content available to Telewee
                      Technology LLC, a limited liability company organized
                      under the laws of UAE (together with its Affiliates
                      "Telewee", "we" or "us") for distribution, monetizing and
                      licensing through the Telewee website at www.Telewee.com
                      (the "Website"). Please read this Agreement carefully.
                    </div>

                    <h1 className="text-center text-xl font-bold mb-7 mt-4 underline">
                      First: The Scope of the Agreement
                    </h1>

                    <div className="mt-5">
                      a. This Agreement applies to any content and work,
                      including video footage, audio visual works, photographs,
                      illustrations, music and other audio files, animations, 3D
                      models, motion graphics, VR 360, data files, program
                      templates and other audiovisual and digital media works
                      uploaded and/or submitted by or for you to us
                      (individually and collectively, "Content") and any
                      information, releases, and/or media work (tags, captions,
                      metadata, and/or annotations) provided by or for you to us
                      relating to Content ("Content Information").
                    </div>
                    <div className="mt-5">
                      b. In order to monetize Content through Telewee, it is
                      necessary to possess and uphold a Telewee account that
                      permits the monetization and distribution of Content,
                      along with supplying all necessary personal information as
                      specified in the Personal Data Form as available on the
                      Website (“Registered Website Account”).
                    </div>
                    <div className="mt-5">
                      c. We reserve the right, at our sole discretion, and for
                      any reason whatsoever to accept or reject any Content or
                      Content Information, or at any time revoke any acceptance
                      of Content or Content Information and remove the same from
                      the Website. We may also use automated systems designed to
                      analyze your Content for the purpose of detecting cases of
                      infringement and abuse, including but not limited to:
                      spam, malware, and any content we believe, in our sole
                      opinion, is illegal content.
                    </div>

                    <h1 className="text-center text-xl font-bold mb-7 mt-4 underline">
                      Second: Grant of Rights by you to Telewee
                    </h1>
                    <div className="mt-5">
                      Upon uploading Content on the Website, You are hereby
                      granting us the following:
                    </div>
                    <div className="mt-5">
                      a. The exclusive and world-wide, right to use, sell,
                      distribute, copy, market, promote, display, monetize
                      and/or grant licenses for the Content, including Content
                      Information, either directly or indirectly. The licenses
                      provided by us to third parties through a Content License
                      Agreement, known as a "Telewee Content License Agreement,"
                      may incorporate, as we see fit, certain or all of the
                      rights, restrictions, and/or other provisions stipulated
                      in the Telewee Contributor Agreement as available on the
                      Website. These terms are subject to modification, from
                      time to time, by us at our sole discretion, and/or any
                      alterations thereof.
                    </div>
                    <div className="mt-5">
                      b. The grant of rights over the Content including the
                      Content Information under this Agreement shall be granted
                      exclusively to Telewee in perpetuity.
                    </div>
                    <div className="mt-5">
                      c. You, or if different, the owner of the copyright in the
                      Content, acknowledges and agrees that upon submission of
                      the Content, all rights, title, copyright ownership,and
                      interest in and to the Content shall be transferred to us.
                      You retain no ownership rights in the Content.
                    </div>
                    <div className="mt-5">
                      d. You hereby permanently waive any "moral rights"
                      associated with the Content, which includes the right to
                      be acknowledged as the author of the Content or to object
                      to any alterations made to the Content. If you are not the
                      original creator, you confirm that you have obtained such
                      a waiver from the author.
                    </div>
                    <div className="mt-5">
                      e. We hereby, upon the rights granted by you to the
                      Content, are entitled to edit, resize, resample, convert,
                      color correct, watermark, crop or otherwise manipulate the
                      Content. Additionally, we may edit or supplement any
                      Content Information to correct what we, at our sole
                      discretion, consider to be an error, misleading statement,
                      or omission in the Content Information, or for the purpose
                      of promoting, distributing, selling, and licensing the
                      Content. However, it's important to note that any
                      examination, review, correction, or editing of the Content
                      or Content Information carried out by us is purely a
                      courtesy, and we assume no liability for these actions or
                      for any failure to perform them.
                    </div>

                    <h1 className="text-center text-xl font-bold mb-7 mt-4 underline">
                      Third: Revenues.
                    </h1>
                    <div className="mt-5">
                      a. Revenue Sharing. Pursuant to Section (Second) of this
                      Agreement, You will be entitled a percentage of the Net
                      Revenue collected by us from monetizing, marketing,
                      selling, and/or licensing Your Content as follows: 40% of
                      Net Revenue (“Contributor’s Revenue”).
                    </div>
                    <div className="mt-5">
                      b. Payments. We will pay the Contributor’s Revenue within
                      Sixty (60) days of the end of each month in which such
                      revenue was earned. Telewee will make all payments to your
                      PayPal account or other payment method supported by
                      Telewee or, to the bank account provided by you in your
                      Registered Website Account at Telewee Website.
                    </div>
                    <div className="mt-5">
                      c. Accounting. The Website shall provide you with regular
                      accounting statements showing the revenue generated and
                      Your share of the revenue.
                    </div>
                    <div className="mt-5">
                      d. Currency. Payments will be paid in (US Dollars).
                      Subject to Telewee’s approval payments may be made in
                      different currencies if you request payment of
                      Contributor’s Revenue in a currency other than (US
                      Dollars), we will charge a foreign exchange fee.
                    </div>

                    <h1 className="text-center text-xl font-bold mb-7 mt-4 underline">
                      Forth: Taxes
                    </h1>
                    <div className="mt-5">
                      We may exclude or deduct any of the following from the
                      calculation of the Net Revenues and the Contributor’s
                      Revenue payable to you: (A) any taxes or withholdings paid
                      by a customer or as decided to be required by the
                      applicable laws; (B) chargebacks, refunds, and
                      uncollectible amounts; and (C) expenses, fees, cost
                      payable or deducted by financial institutions for
                      processing credit card, debit card, e-check, or
                      alternative payment method transactions, as well as
                      currency conversion charges for payments received by us or
                      remitted to you in a currency other than US Dollars.
                    </div>

                    <h1 className="text-center text-xl font-bold mb-7 mt-4 underline">
                      Fifth: Your Liability for Third-Party IP Rights
                    </h1>
                    <div className="mt-5">
                      a. In the context of the Content monetization under this
                      Agreement, You shall assume full responsibility and
                      liability for any Content uploaded to the Website that may
                      depict or incorporate third-party intellectual property
                      rights, including but not limited to copyrights,
                      trademarks, patents, trade secrets, or any other
                      proprietary rights ("Third-Party IP Rights"). You hereby
                      agrees to indemnify, defend, and hold harmless Telewee,
                      its affiliates, directors, employees, agents, counsels and
                      partners from any claims, damages, losses, or legal
                      actions arising from or related to the unauthorized use,
                      infringement, or misappropriation of Third-Party IP Rights
                      within the uploaded Content.
                    </div>
                    <div className="mt-5">
                      b. You further acknowledge and agree that any violation of
                      third-party intellectual property rights through their
                      uploaded content may result in immediate removal of the
                      Content from the Website and the potential termination of
                      this Agreement, without any liability to Telewee.
                    </div>
                    <div className="mt-5">
                      c. This provision underlines Your obligation to ensure
                      that all content You contribute to the Website respects
                      and does not infringe upon any third-party intellectual
                      property rights, and that You shall bear the legal and
                      financial consequences of any such infringement.
                    </div>
                    <div className="mt-5">
                      d. By accepting this Agreement, You acknowledge that You
                      have read, understood, and agreed to the terms and
                      conditions set forth herein regarding Your liability for
                      any content that may depict third-party intellectual
                      property rights.
                    </div>

                    <h1 className="text-center text-xl font-bold mb-7 mt-4 underline">
                      Sixth: Disclaimer, Representations, and Warranties
                    </h1>
                    <div className="mt-5">
                      a. By accepting this Agreement and for each Content
                      submission, you make the following representations and
                      warranties: i. Your Content is original and does not
                      infringe any third-party copyrights, trademarks, rights of
                      privacy, rights of publicity, moral rights, or proprietary
                      rights, nor does it defame any third party. ii. You have
                      the full legal capacity and authority to enter this
                      Agreement, grant all the rights to us, and fulfill your
                      obligations. You also represent that You own the Content,
                      its parts, and copyrights, or have obtained the necessary
                      rights from its legal owner which allows You to transfer
                      and assign all the rights of such Content to us including
                      the right to sub-license any of the Content to others. You
                      have not granted conflicting rights or licenses. iv. If an
                      individual, you are of legal age to grant all rights
                      stated in this Agreement. v. Personal Data and Information
                      provided to us in accordance with the Personal Data Form
                      as per the applicable laws and regulations, including
                      payment account details, is accurate and complete. You
                      agree to update it as needed. We may withhold payments
                      until we receive necessary information for verification or
                      compliance. vi. If the Content includes third-party
                      elements, their license agreements allow incorporation
                      into the Content and the licenses granted herein. vii. any
                      Content submitted or uploaded to the Website has no
                      disabling mechanisms, viruses, or harmful elements. viii.
                      All Content Information is accurate, complete, and not
                      misleading. Metadata is not used improperly to alter
                      search results. ix. any Content provided, uploaded, and/or
                      submitted to the Website do not violate any laws or
                      contract terms. x. You will promptly notify us if any
                      representation in this Agreement becomes incomplete,
                      inaccurate, or misleading, and you will remove the Content
                      if necessary.
                    </div>

                    <div className="mt-5">
                      b. If a representative on your behalf uploads Content on
                      the Website, both you and that person represent that they
                      are the Registered Website Account user providing the
                      Content and have the authority to act on your behalf.
                    </div>

                    <h1 className="text-center text-xl font-bold mb-7 mt-4 underline">
                      Seventh: Indemnification
                    </h1>
                    <div className="mt-5">
                      a. You hereby agree and undertake to defend, indemnify and
                      hold us, our Affiliates, and our and their respective
                      Representatives and any related parties , including our
                      Content users, resellers, Licensees, and the Content users
                      harmless from and against any and all losses arising out
                      of or relating to any claim, relating to or arising
                      directly or indirectly out of claims, failures such as:(i)
                      a claim raised due to your breach and/or violation
                      (whether made by you or any of your representatives) or
                      any defaulting act including representation, warranty, or
                      obligation contained herein, or (ii). an alleged failure
                      to fulfill any responsibility, representation, warranty or
                      obligation you have assumed under this Agreement.
                    </div>
                    <div className="mt-5">
                      b. We reserve our right to withhold any amounts as a form
                      of security for any pending or threatened Claim related to
                      any matter subject to a representation, warranty, or
                      indemnity under this Agreement, as well as amounts we
                      decide that are required to be withheld by law.
                    </div>
                    <div className="mt-5">
                      c. You are also obligated to the following: (i) provide us
                      with an immediate notification of any claim, request
                      and/or demand; and (ii) assist us and cooperate in the
                      defense of any claim.
                    </div>

                    <h1 className="text-center text-xl font-bold mb-7 mt-4 underline">
                      Eighth: LIMITATION OF LIABILITY
                    </h1>
                    <div className="mt-5">
                      a. TO THE EXTENT PERMITTED BY APPLICABLE LAW, Telewee
                      SHALL NOT BE LIABLE TO YOU OR ANY OTHER PARTY OR ENTITY
                      FOR ANY GENERAL, INDIRECT, INCIDENTAL, SPECIAL,
                      CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES, INCLUDING
                      DAMAGES FOR LOSS OF BUSINESS, PROFITS, GOODWILL, DATA, OR
                      OTHER INTANGIBLE LOSSES, EVEN IF WE HAS BEEN ADVISED OF
                      THE POSSIBILITY OF SUCH DAMAGES, LOSS AND EXPENSE WHETHER
                      IT WAS BASED BY CONTRACT, INFRINGEMENT OF INTELLECTUAL
                      PROPERTY RIGHTS ,OR TORT (INCLUDING NEGLIGENCE AND
                      MISCONDUCT) OR OTHERWISE.
                    </div>
                    <div className="mt-5">
                      b. YOU ASSUME ALL RESPONSIBILITY AND RISK FOR USE ANY
                      CONTENT OR INFORMATION CONTAINED THEREIN.
                    </div>
                    <div className="mt-5">
                      c. YOU AGREE THAT Telewee AND ITS SUBSIDIARIES AND
                      AFFILIATES ARE NOT LIABLE FOR ANY ERRORS IN PRICING OR ANY
                      LOSS OR DAMAGE TO CONTENT OR MATERIAL SUBMITTED TO THE
                      WEBSITE AND YOU ARE REQUIRED TO MAINTAIN YOUR OWN BACKUP
                      FILES FOR ANY CONTENT SUBMITTED TO US.
                    </div>
                    <div className="mt-5">
                      d. WE WILL NOT BE RESPONSIBLE FOR ANY FAILURE OR DELAY TO
                      IMPLEMENT ANY OF OUR OBLIGATIONS DUE TO UNFORESEEABLE
                      REASONS IF SUCH ARISES FROM ANY ACT OF GOD, ACT OF
                      GOVERNMENT, WAR, STRIKES, ANY ACT ARISES FROM FAILURE OF
                      UTILITY, COMMUNICATIONS, TECHNICAL, HARDWARE, SOFTWARE
                      ISSUES ANY CAUSE WHICH IS BEYOND OUR REASONABLE CONTROL.
                    </div>

                    <h1 className="text-center text-xl font-bold mb-7 mt-4 underline">
                      Ninth: Governing Law & Jurisdiction
                    </h1>
                    <div className="mt-5">
                      a. Governing Law. This Agreement shall be governed by and
                      construed in accordance with the laws of UAE, without
                      regard to its conflict of laws principles.. The United
                      Nations Convention on Contracts for the International Sale
                      of Goods does not govern this Agreement. The rights
                      granted herein, any upload or submission of Content,
                      access to and use of the Website and the entering into
                      this Agreement will be deemed to take place in the Emirate
                      of Sharjah in the United Arab Emirates.
                    </div>
                    <div className="mt-5">
                      b. Jurisdiction. Any dispute, difference, controversy or
                      claim arising out of or in connection with this contract,
                      including (but not limited to) any question regarding its
                      existence, validity, interpretation, performance,
                      discharge and applicable remedies, shall be subject to the
                      exclusive jurisdiction of the Courts of Sharjah.
                    </div>

                    <h1 className="text-center text-xl font-bold mb-7 mt-4 underline">
                      Tenth: Termination
                    </h1>
                    <div className="mt-5">
                      a. Termination for convenience. We may terminate this
                      Agreement at any time for convenience upon serving a
                      written notice to You.
                    </div>
                    <div className="mt-5">
                      b. Termination for Violation. We may at any time and for
                      any reason (including your submission to us of infringing
                      Content) remove any or all Content from the Website and
                      cease offering sub-licenses to the same (the "Terminated
                      Content").
                    </div>
                    <div className="mt-5">
                      c. Effect of Termination. Notwithstanding any other
                      provision in this Agreement: (i) removal of any Content
                      from the Website and/or the termination or expiration of
                      this Agreement or any rights granted to us hereunder will
                      not terminate, alter or otherwise affect any license
                      granted to a distributors, licenses under Telewee Content
                      License prior to the effective date of such removal,
                      termination or expiration; (ii) Upon termination, we
                      reserve the right to withhold all outstanding payments
                      owed to You for a duration of Ninety (90) days to assess
                      any potential set-off rights. During this period, we may
                      subtract a reasonable administrative fee from these
                      amounts for the purpose of initiating, managing and
                      terminating your account.
                    </div>
                    <div className="mt-5">
                      d. The terms and provisions of Section (third) to Section
                      (Twelfth) and any license granted by us or any of our
                      Distributors shall survive termination or expiration of
                      this Agreement for any reason.
                    </div>

                    <h1 className="text-center text-xl font-bold mb-7 mt-4 underline">
                      Eleventh: Miscellaneous
                    </h1>
                    <div className="mt-5">
                      a. You hereby Consent to electronic communication and we
                      may send you any notices to the email address and any
                      other types of communication as provided by You in the
                      Registered Website Account and the Personal Data Form and
                      You agree that receiving any communications from Us using
                      such method is legally satisfactory to You. .
                    </div>
                    <div className="mt-5">
                      b. We have the right, in our sole discretion, to amend or
                      make changes to this Agreement or any agreements or Terms
                      referred to in this Agreement at any time and for any
                      reason, provided that your obligation to adhere with such
                      amendments will occur immediately upon receiving our
                      notification you with such changes and/or amendments in
                      due course.
                    </div>

                    <div className="mt-5">
                      c. The parties to this Agreement are independent
                      contractors, and nothing in this Agreement or the rights
                      granted herein, any upload or submission of Content or
                      access to or use of the Website shall create a joint
                      venture, partnership, employment relationship, or
                      franchise or fiduciary relationship between the parties.
                    </div>

                    <div className="mt-5">
                      d. If any provision, or portion thereof, of this
                      Agreement, or its application to any Person or
                      circumstance, shall be invalid, illegal or unenforceable
                      to any extent, the remainder of this Agreement, such
                      provision and their application shall not be affected
                      thereby, but shall be interpreted without such
                      unenforceable provision or portion thereof so as to give
                      effect, insofar as is possible, to the original intent of
                      the parties, and shall otherwise be enforceable to the
                      fullest extent permitted by law.
                    </div>

                    <div className="mt-5">
                      e. You acknowledge and consent that we have the authority
                      to consider that all information used signing up of
                      Registered Website Account including your registered
                      website username, or that of any such person who applies
                      and completes a Personal Data Form or provides a user ID
                      and password, as definitive proof of your intention to
                      upload and submit Content and Content Information in
                      accordance with the terms of this Agreement.
                    </div>

                    <div className="mt-5">
                      f. This Agreement shall be read with all terms and
                      conditions available on our Website including Our Website
                      Terms and Conditions, privacy policy, guidelines, and
                      restrictions contained at the Website where you upload
                      Content as modified by us from time to time in our sole
                      discretion (collectively, the "Website Terms") and the
                      Website; provided that in the event of any inconsistency
                      between this Agreement and any such Website Terms, the
                      terms of this Agreement shall govern.
                    </div>

                    <div className="mt-5">
                      g. All of the covenants, terms, provisions and agreements
                      contained in this Agreement shall be binding upon, and
                      inure to the benefit of, the parties hereto and, to the
                      extent permitted by this Agreement, their respective
                      heirs, legal representatives, successors and permitted
                      assigns..
                    </div>

                    <div className="mt-5">
                      h. This Agreement is personal to you and may not be
                      transferred or assigned by you without our prior written
                      approval. We reserve the right to transfer or assign this
                      Agreement to any other party without requiring your
                      consent, provided that such party agrees to be bound by
                      its provisions.
                    </div>

                    <div className="mt-5">
                      i. DISCLOSURE OF INFORMATION: We reserve the right to
                      report any activity that either it suspects violates any
                      Applicable Law to appropriate law enforcement officials,
                      regulators, or other third parties. In order to cooperate
                      with governmental requests, to protect Telewee.com, or to
                      ensure the integrity and operation of Telewee.com’s
                      business and systems, Telewee.com may access and disclose
                      any information it considers necessary or appropriate,
                      including but not limited to Seller account details,
                      contact details, IP address and traffic information, usage
                      history, and posted content.
                    </div>

                    <h1 className="text-center text-xl font-bold mb-7 mt-4 underline">
                      Twelfth: Acknowledgement and Acceptance of this Agreement
                    </h1>
                    <div className="mt-5">
                      a. You affirm and acknowledge that you have thoroughly
                      read and reviewed this Agreement and any other agreements
                      or terms that may be referenced herein, comprehend and
                      understand their contents, and had the opportunity to
                      consult with independent legal counsel before giving your
                      consent.
                    </div>

                    <div className="mt-5">
                      b. By clicking or marking "I Agree" or any similar method
                      of indicating acceptance, the person taking this action
                      acknowledges and consents to be legally bound by this
                      Agreement, both individually and as a representative of
                      the entity identified as the Registered Website Account,
                      including any terms and conditions set forth within it.
                      Notwithstanding to anything stipulated in this Agreement
                      and in addition to any rights and remedies available in
                      this Agreement and/or by Law, we reserve our rights to
                      suspend the Registered Website Account and/or terminate
                      this Agreement if we believe that a person accepting this
                      Agreement and any terms available on the Website, is not
                      authorized or has the legal capacity to practice
                      acceptance on behalf the Content author and/or the owner
                      of the IP rights thereof. Therefore, If such person does
                      not have such right, power and authority or you do not
                      agree with these terms, do not accept the Agreement and do
                      not upload or otherwise submit anything to the Website or
                      us.
                    </div>





                    {/* <h1 className="text-center text-xl font-bold mb-7 mt-4 underline">
                      Thirteenth: Social Media Accounts Scrapping
                    </h1>
                    <div className="mt-5">
                    • Telewee scrapes user/teleweeler videos and reels from their instagram profiles if they provided their instagram profile url during the onboarding process or from the settings, By entering the instagram url profile you allow and accept that Telewee, will scrape your videos and reels automatically.
                    </div> */}













                    <h2 className="text-center text-xl font-bold mb-7 mt-4 underline">
                    Thirteenth: Rights Granted to the Website{" "}
        </h2>
        <p className="font-DMsansRegular mb-2">
        <span className="text-bold bold">a. Your content. </span>By posting or submitting content to the Website, you grant [Telewee] a license and permission to:

<div>
- stream, make the content available for download, and share your content with end users.
</div>
<div>

- promote and distribute the content through our Content License Agreement.
</div>
<div>
 
- alter, modify, develop, or omit any content and/or any part thereof.
</div>
<div>

 - Consent of uploading your videos on [Telewee], from your social media platforms (this consent is an optional choice and it can be disabled by removing the social media URL in your profile settings).
 </div>

 <div className="mt-8">
 b. Your feedback. You are welcome to share your comments, suggestions, or any other feedback regarding our Services. By doing so, you grant Telewee a non-exclusive, worldwide, royalty-free license to use irrevocable right, license, and permission to use, transmit, copy distribute, publicly present, share, and display that feedback in any way, without the obligation to provide any compensation to you on a perpetual basis. 
 </div>

 <div className="mt-8">
 All the licenses granted under this section are non-exclusive, worldwide, royalty-free licenses to use, reproduce, modify, adapt, and distribute the content for the purpose of operating and promoting the services. If you choose to monetize your content then you will be also subject to the terms and conditions of our Content Partner Agreement available on the Website. 
 </div>


 <h2 className="text-center text-xl font-bold mb-7 mt-4 underline">
        Forteenth: Contact us{" "}
        </h2>

        <div>
        If you have any questions, concerns or need further clarification regarding the Website or these Terms & Conditions, please contact us at Email: Support@telewee.com
        </div>

        </p>











                    

                    {/* <div className="mt-5">
                      b. By clicking or marking "I Agree" or any similar method
                      of indicating acceptance, the person taking this action
                      acknowledges and consents to be legally bound by this
                      Agreement, both individually and as a representative of
                      the entity identified as the Registered Website Account,
                      including any terms and conditions set forth within it.
                      Notwithstanding to anything stipulated in this Agreement
                      and in addition to any rights and remedies available in
                      this Agreement and/or by Law, we reserve our rights to
                      suspend the Registered Website Account and/or terminate
                      this Agreement if we believe that a person accepting this
                      Agreement and any terms available on the Website, is not
                      authorized or has the legal capacity to practice
                      acceptance on behalf the Content author and/or the owner
                      of the IP rights thereof. Therefore, If such person does
                      not have such right, power and authority or you do not
                      agree with these terms, do not accept the Agreement and do
                      not upload or otherwise submit anything to the Website or
                      us.
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className={`inline-flex justify-center w-full rounded-md px-4 py-2
                  bg-[#FF5454] cursor-pointer text-sm font-medium text-white
                 `}
                  onClick={(e) => {
                    SubmitFunc(e);
                    setOpen(false);
                  }}
                >
                  Accept
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
