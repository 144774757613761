import { useTranslation } from "react-i18next";
import CountUp from "react-countup";

import HowItWorks from "../components/module/Landing/HowItWorks";
import OurValues from "../components/module/Landing/OurValues";
import Trending from "../components/module/Landing/Trending";
import FAQ from "../components/module/Landing/FAQ";
import Footer from "../components/module/Landing/Footer";
import Section1V2 from "../components/module/LandingV2/Section1V2";

export default function Landing() {
  return (
    <div>
      <Section1V2 />
      <Stat />
      <HowItWorks />
      <Trending />
      <OurValues />
      <FAQ />
      <Footer />
    </div>
  );
}

function Stat() {
  const { t } = useTranslation();

  const stats = [
    { id: 1, name: t("total_telewelers"), value: 1000 },
    { id: 2, name: t("total_videos"), value: 5000 },
    { id: 3, name: t("total_views"), value: 5000000 },
  ];
  return (
    <div className="bg-white mt-14">
      <div className="mx-auto max-w-6xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="text-center">
            <h2 className="text-palette-watermelon font-DMsansExtraBold tracking-tight text-3xl lg:text-4xl">
              {t("our_numbers")}
            </h2>
            {/* <p className="text-center text-lg leading-8 text-gray-600 font-DMsansRegular my-1">
              {t("our_numbers_desc")}.
            </p> */}
          </div>
          <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-3">
            {stats.map((stat) => (
              <div key={stat.id} className="flex flex-col bg-gray-400/5 p-8">
                <dt className="text-base font-DMsansSemiBold leading-6 text-gray-600">
                  {stat.name}
                </dt>

                <dd className="text-palette-watermelon font-DMsansExtraBold order-first text-4xl font-semibold tracking-tight text-gray-900 my-1">
                  + <CountUp end={stat.value} separator="," />
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
