import React from "react";
import "../../assets/loader.css";

function Loader({ percentage = null }) {
  return (
    <div className="loader-overlay ">
      <div>
        <div className="loader"></div>
        {percentage ? (
          <div className="mx-2 text-white text-base font-bold mt-2">
            {percentage}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Loader;
