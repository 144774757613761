// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Loader.css */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Lower opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #ff5454;
  width: 50px;
  height: 50px; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/loader.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC,EAAE,kBAAkB;EACxD,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,8BAA8B;EAC9B,WAAW;EACX,YAAY,EACgC,WAAW;EACvD,kCAAkC;AACpC;;AAEA,WAAW;;AAUX;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["/* Loader.css */\n.loader-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.3); /* Lower opacity */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 99999;\n}\n\n.loader {\n  border: 8px solid #f3f3f3;\n  border-radius: 50%;\n  border-top: 10px solid #ff5454;\n  width: 50px;\n  height: 50px;\n  -webkit-animation: spin 1s linear infinite; /* Safari */\n  animation: spin 2s linear infinite;\n}\n\n/* Safari */\n@-webkit-keyframes spin {\n  0% {\n    -webkit-transform: rotate(0deg);\n  }\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
