import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";

import { ReactComponent as FAQIcon } from "../../../assets/FAQ.svg";
import { ReactComponent as ArrowCircleDown } from "../../../assets/ArrowCircleDown.svg";

export default function FAQ() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t } = useTranslation();

  const FAQ = [
    {
      q: t("Q1"),
      a: t("A1"),
      isActive: false,
    },
    {
      q: t("Q2"),
      a: t("A2"),
      isActive: false,
    },
    {
      q: t("Q3"),
      a: t("A3"),
      isActive: false,
    },
    {
      q: t("Q4"),
      a: t("A4"),
      isActive: false,
    },
    {
      q: t("Q5"),
      a: t("A5"),
      isActive: false,
    },
    // {
    //   q: t("Q6"),
    //   a: t("A6"),
    //   isActive: false,
    // },
    // {
    //   q: t("Q7"),
    //   a: t("A7"),
    //   isActive: false,
    // },
    // {
    //   q: t("Q8"),
    //   a: t("A8"),
    //   isActive: false,
    // },
  ];

  // const handleItemClick = (index) => {
  //   const updatedFAQ = FAQ.map((item, i) => {
  //     if (i === index) {
  //       return { ...item, isActive: !item.isActive };
  //     } else {
  //       return { ...item, isActive: false };
  //     }
  //   });

  //   setFAQ(updatedFAQ);
  // };

  return (
    <div
      dir={currentLanguageCode === "ar" ? "rtl" : "ltr"}
      className="max-w-7xl mx-auto mt-20 px-5 mb-20"
    >
      <div className="text-center">
        <h1 className="text-palette-watermelon font-DMsansExtraBold text-center text-4xl lg:text-5xl mb-4">
          {t("FrequentlyAskedQuestions")}
        </h1>
        <p className="max-w-4xl mx-auto font-DMsansRegular text-base lg:text-xl px-4 text-[#979797]">
          {t("Do-you-have-a-question-Were-here-to-help-you")}
        </p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-0 lg:mt-20">
        <div className="mx-auto">
          <FAQIcon className="w-[328px] h-[328px] lg:w-[555px] lg:h-[555px]" />
        </div>
        <div className="my-auto">
          {FAQ.map((item, i) => {
            return (
              <div
                key={i}
                // onClick={() => handleItemClick(i)}
                className="border-b border-[#979797] py-4 last:border-b-0 cursor-pointer"
              >
                <div className="flex justify-between ">
                  <div className="font-DMsansBold">{item.q}</div>
                  {/* <ArrowCircleDown
                    className={`${item.isActive ? "rotate-180" : ""}`}
                  /> */}
                </div>
                {/* {item.isActive ? ( */}
                <div className="mt-3 text-gray-700 font-DMsansRegular">
                  {item.a}
                </div>
                {/* ) : null}  */}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
