import React, { useState, useEffect } from "react";
import { editUserAPI, dashboardAPI } from "../services/apis/User";
import Layout from "../components/common/Layout";
import { ReactComponent as Lock } from "../assets/login/Lock.svg";
import { ReactComponent as Eye } from "../assets/login/Eye.svg";
import { ReactComponent as CloudUpload } from "../assets/CloudUpload.svg";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";

export default function Settings() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t } = useTranslation();

  const [activeSettingsTab, setactiveSettingsTab] = useState("PersonalInfo");
  const [activeBillingType, setactiveBillingType] = useState("paypal");

  const [userInfo, setuserInfo] = useState({});

  async function editUser() {
    let data = userInfo;

    switch (activeSettingsTab) {
      case "PersonalInfo":
        data = {
          user: userData._id,
          full_name: data.full_name,
          email_address: data.email_address,
          phone_number: data.phone_number,
          instagram_url:data.instagram_url?data.instagram_url:""
        };
        break;

      case "ProfitDetails":
        if (activeBillingType === "paypal") {
          data = {
            user: userData._id,
            paypal_email: data.paypal_email,
          };
        } else {
          data = {
            user: userData._id,
            iban_data: data.iban_data,
          };
        }

        break;

      case "ChangePassword":
        if (data.old_password && data.new_password && data.confirm_password) {
          if (data.old_password === userData.password) {
            if (data.new_password === data.confirm_password) {
              data = {
                user: userData._id,
                password: data.new_password,
              };
            } else {
              toast.error("Password mismatch.", {
                transition: Slide,
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          } else {
            toast.error("Wrong old password.", {
              transition: Slide,
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else {
          toast.error("Please make sure to fill all fields.", {
            transition: Slide,
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        break;
    }

    await editUserAPI(data)
      .then((res) => {
        toast.success("Successfully edited.", {
          transition: Slide,
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => console.log(err));
  }

  async function get_dashboard_data() {
    await dashboardAPI(userData._id)
      .then((res) => {
        setuserInfo(res.userInfo);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    get_dashboard_data();
  }, []);

  return (
    <Layout>
      {/* Desktop view */}
      <div className="hidden lg:flex max-w-[90rem]  mx-auto mt-14 h-screen">
        <div className="basis-2/5">
          <div className="flex justify-center mx-auto">
            <div>
              <h1 className="text-3xl font-bold">{t("Settings")}</h1>

              <div className="my-5 space-y-3">
                <div
                  onClick={() => setactiveSettingsTab("PersonalInfo")}
                  className={`px-10 py-2 rounded-lg cursor-pointer ${
                    activeSettingsTab === "PersonalInfo"
                      ? "bg-palette-watermelon text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {t("PersonalInformation")}
                </div>

                <div
                  onClick={() => setactiveSettingsTab("ProfitDetails")}
                  className={`px-10 py-2 rounded-lg cursor-pointer ${
                    activeSettingsTab === "ProfitDetails"
                      ? "bg-palette-watermelon text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {t("ProfitDetails")}
                </div>

                <div
                  onClick={() => setactiveSettingsTab("ChangePassword")}
                  className={`px-10 py-2 rounded-lg cursor-pointer ${
                    activeSettingsTab === "ChangePassword"
                      ? "bg-palette-watermelon text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {t("ChangePassword")}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="basis-3/5">
          <div className="bg-white rounded-lg py-20 px-3 shadow mx-20">
            {activeSettingsTab === "PersonalInfo" ? (
              <div>
                <div className="max-w-lg mx-auto space-y-6">
                  <div>
                    <div className="font-medium mb-1">{t("FullName")}</div>
                    <input
                      className="bg-gray-100 rounded-lg w-full p-2 border outline-none"
                      type="text"
                      value={userInfo.full_name}
                      onChange={(e) =>
                        setuserInfo({ ...userInfo, full_name: e.target.value })
                      }
                    />
                  </div>

                  <div>
                    <div className="font-medium mb-1">{t("PhoneNumber")}</div>
                    <input
                      className="bg-gray-100 rounded-lg w-full p-2 border outline-none"
                      type="text"
                      value={userInfo.phone_number}
                      onChange={(e) =>
                        setuserInfo({
                          ...userInfo,
                          phone_number: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div>
                    <div className="font-medium mb-1">{t("Email")}</div>
                    <input
                      className="bg-gray-100 rounded-lg w-full p-2 border outline-none"
                      type="email"
                      value={userInfo.email_address}
                      onChange={(e) =>
                        setuserInfo({
                          ...userInfo,
                          email_address: e.target.value,
                        })
                      }
                    />
                  </div>


                  <div>
                    <div className="font-medium mb-1">{t("InstagramURL")}</div>
                    <input
                      className="bg-gray-100 rounded-lg w-full p-2 border outline-none"
                      type="text"
                      value={userInfo.instagram_url}
                      onChange={(e) =>
                        setuserInfo({
                          ...userInfo,
                          instagram_url: e.target.value,
                        })
                      }
                    />
                  </div>

                  {/* <div>
                    <div className="font-medium mb-1">
                      Upload your legal paper (Passport or ID card)
                    </div>
                    <p className="text-gray-500">
                      * You can request data removal anytime
                    </p>
                    <div className="mt-2 flex justify-center rounded-lg bg-[#F9F9F9] px-6 py-20">
                      <div className="text-center">
                        <div className="mt-4 flex text-base leading-6 text-[#AAAAAA]">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md font-DMsansSemiBold text-palette-watermelon"
                          >
                            <div className="font-DMsansRegular">
                              <CloudUpload
                                className="mx-auto"
                                aria-hidden="true"
                              />
                              <div className="pt-3">Upload a file</div>
                            </div>
                            <input
                              id="file-upload"
                              name="file-upload"
                              type="file"
                              className="sr-only"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div
                  onClick={() => editUser()}
                  className="max-w-lg mx-auto mt-10 mb-5 bg-palette-watermelon p-2 text-center text-white rounded-lg font-medium"
                >
                  {t("Edit")}
                </div>
              </div>
            ) : activeSettingsTab === "ProfitDetails" ? (
              <div>
                <div className="max-w-lg mx-auto space-y-6">
                  <div className="flex  my-5 bg-[#F9F9F9]">
                    <div
                      onClick={() => setactiveBillingType("paypal")}
                      className={`w-full py-2 rounded-lg cursor-pointer my-auto text-center ${
                        activeBillingType === "paypal"
                          ? "bg-palette-watermelon text-white"
                          : "font-medium "
                      }`}
                    >
                      {t("Paypal")}
                    </div>

                    <div
                      onClick={() => setactiveBillingType("bankTransfer")}
                      className={`w-full py-2 rounded-lg cursor-pointer my-auto text-center ${
                        activeBillingType === "bankTransfer"
                          ? "bg-palette-watermelon text-white"
                          : "font-medium text-gray-600"
                      }`}
                    >
                      {t("BankTransfer")}
                    </div>
                  </div>



                  {activeBillingType === "paypal" ? (
                    <div>
                      <div className="font-medium mb-1">
                      {t("Paypal-Email")}

                      </div>
                      <input
                        className="bg-gray-100 rounded-lg w-full p-2 border outline-none"
                        type="email"
                        value={userInfo.paypal_email}
                        onChange={(e) =>
                          setuserInfo({
                            ...userInfo,
                            paypal_email: e.target.value,
                          })
                        }
                        // value={profitDetails.paypal_email}
                        // onChange={(e) =>
                        //   setprofitDetails({
                        //     ...profitDetails,
                        //     paypal_email: e.target.value,
                        //   })
                        // }
                      />
                    </div>
                  ) : (
                    <>
                      <div>
  
                        <div className="font-medium mb-1">{t("IBAN")}</div>
                        <input
                          className="bg-gray-100 rounded-lg w-full p-2 border outline-none"
                          type="text"
                          value={userInfo?.iban_data?.iban}
                          onChange={(e) =>
                            setuserInfo({
                              ...userInfo,
                              iban_data: {
                                ...userInfo.iban_data,
                                iban: e.target.value,
                              },
                            })
                          }
                          // value={profitDetails.iban_data.iban}
                          // onChange={(e) =>
                          //   setprofitDetails({
                          //     ...profitDetails,
                          // iban_data: {
                          //   ...profitDetails.iban_data,
                          //   iban: e.target.value,
                          // },
                          //   })
                          // }
                        />
                      </div>

                      <div>
                        <div className="font-medium mb-1">{t("Swift Code")}</div>
                        <input
                          className="bg-gray-100 rounded-lg w-full p-2 border outline-none"
                          type="text"
                          value={userInfo?.iban_data?.swift}
                          onChange={(e) =>
                            setuserInfo({
                              ...userInfo,
                              iban_data: {
                                ...userInfo.iban_data,
                                swift: e.target.value,
                              },
                            })
                          }
                          // value={profitDetails.iban_data.swift}
                          // onChange={(e) =>
                          //   setprofitDetails({
                          //     ...profitDetails,
                          //     iban_data: {
                          //       ...profitDetails.iban_data,
                          //       swift: e.target.value,
                          //     },
                          //   })
                          // }
                        />
                      </div>

                      <div>
                        <div className="font-medium mb-1">{t("BankName")}</div>
                        <input
                          className="bg-gray-100 rounded-lg w-full p-2 border outline-none"
                          type="text"
                          value={userInfo?.iban_data?.bank}
                          onChange={(e) =>
                            setuserInfo({
                              ...userInfo,
                              iban_data: {
                                ...userInfo.iban_data,
                                bank: e.target.value,
                              },
                            })
                          }
                          // value={profitDetails.iban_data.bank}
                          // onChange={(e) =>
                          //   setprofitDetails({
                          //     ...profitDetails,
                          //     iban_data: {
                          //       ...profitDetails.iban_data,
                          //       bank: e.target.value,
                          //     },
                          //   })
                          // }
                        />
                      </div>

                      {/* <div>
                        <div className="font-medium mb-1">Bank Branch</div>
                        <input className="bg-gray-100 rounded-lg w-full p-2 border outline-none" />
                      </div> */}
                    </>
                  )}
                </div>
                <div
                  onClick={() => editUser()}
                  className="max-w-lg mx-auto my-10 bg-palette-watermelon p-2 text-center text-white rounded-lg font-medium"
                >
                  {t("Add")}
                </div>
              </div>
            ) : (
              <div>
                <div className="max-w-lg mx-auto space-y-6">
                  <div>
                    <div className="font-medium mb-1">{t("OldPassword")}</div>
                    <div className="flex bg-gray-100 border rounded-lg px-3">
                      <Lock className="my-auto" />

                      <input
                        className="w-full p-3 rounded-lg text-sm outline-none bg-gray-100"
                        placeholder={t("Enter-your-old-password")}
                        type="password"
                        value={userInfo.old_password}
                        onChange={(e) =>
                          setuserInfo({
                            ...userInfo,
                            old_password: e.target.value,
                          })
                        }
                      />

                      <Eye className="my-auto text-[#A5A3A9]" />
                    </div>
                  </div>

                  <div>
                    <div className="font-medium mb-1">{t("NewPassword")}</div>
                    <div className="flex bg-gray-100 border rounded-lg px-3">
                      <Lock className="my-auto" />

                      <input
                        className="w-full p-3 rounded-lg text-sm outline-none bg-gray-100"
                        placeholder={t("Enter-your-new-password")}
                        type="password"
                        value={userInfo.new_password}
                        onChange={(e) =>
                          setuserInfo({
                            ...userInfo,
                            new_password: e.target.value,
                          })
                        }
                      />

                      <Eye className="my-auto text-[#A5A3A9]" />
                    </div>
                  </div>

                  <div>
                    <div className="font-medium mb-1">
                      {t("Confirm-new-password")}
                    </div>
                    <div className="flex bg-gray-100 border rounded-lg px-3">
                      <Lock className="my-auto" />

                      <input
                        className="w-full p-3 rounded-lg text-sm outline-none bg-gray-100"
                        placeholder={t("Confirm-new-password")}
                        type="password"
                        value={userInfo.confirm_password}
                        onChange={(e) =>
                          setuserInfo({
                            ...userInfo,
                            confirm_password: e.target.value,
                          })
                        }
                      />

                      <Eye className="my-auto text-[#A5A3A9]" />
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => editUser()}
                  className="max-w-lg mx-auto my-10 bg-palette-watermelon p-2 text-center text-white rounded-lg font-medium"
                >
                  {t("ChangePassword")}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Mobile view */}
      <div className="block lg:hidden h-screen my-5">
        <div className=" bg-white rounded-lg py-10 shadow mx-3 ">
          <div className="flex justify-center mx-auto">
            <div
              onClick={() => setactiveSettingsTab("PersonalInfo")}
              className={`${
                activeSettingsTab === "PersonalInfo"
                  ? "border-palette-watermelon text-palette-watermelon"
                  : "border-gray-300 text-gray-500 hover:border-gray-300 hover:text-gray-700 "
              }
          font-regular cursor-pointer whitespace-nowrap border-b px-2 py-2 text-sm 
          `}
            >
              {t("PersonalInformation")}
            </div>

            <div
              onClick={() => setactiveSettingsTab("ProfitDetails")}
              className={`${
                activeSettingsTab === "ProfitDetails"
                  ? "border-palette-watermelon text-palette-watermelon"
                  : "border-gray-300 text-gray-500 hover:border-gray-300 hover:text-gray-700 "
              }
          font-regular cursor-pointer whitespace-nowrap border-b px-2 py-2 text-sm 
          `}
            >
              {t("ProfitDetails")}
            </div>

            <div
              onClick={() => setactiveSettingsTab("ChangePassword")}
              className={`${
                activeSettingsTab === "ChangePassword"
                  ? "border-palette-watermelon text-palette-watermelon"
                  : "border-gray-300 text-gray-500 hover:border-gray-300 hover:text-gray-700 "
              }
          font-regular cursor-pointer whitespace-nowrap border-b px-2 py-2 text-sm 
          `}
            >
              {t("ChangePassword")}
            </div>
          </div>

          <div className="my-7">
            {activeSettingsTab === "PersonalInfo" ? (
              <div className="mx-4">
                <div className="max-w-lg mx-auto space-y-6">
                  <div>
                    <div className="font-medium mb-1">{t("FullName")}</div>
                    <input
                      className="bg-gray-100 rounded-lg w-full p-2 border outline-none"
                      value={userInfo.full_name}
                      onChange={(e) =>
                        setuserInfo({ ...userInfo, full_name: e.target.value })
                      }
                    />
                  </div>

                  <div>
                    <div className="font-medium mb-1">{t("PhoneNumber")}</div>
                    <input
                      className="bg-gray-100 rounded-lg w-full p-2 border outline-none"
                      value={userInfo.phone_number}
                      onChange={(e) =>
                        setuserInfo({
                          ...userInfo,
                          phone_number: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div>
                    <div className="font-medium mb-1">{t("Email")}</div>
                    <input
                      className="bg-gray-100 rounded-lg w-full p-2 border outline-none"
                      value={userInfo.email_address}
                      onChange={(e) =>
                        setuserInfo({
                          ...userInfo,
                          email_address: e.target.value,
                        })
                      }
                    />
                  </div>


                  <div>
                    <div className="font-medium mb-1">{t("InstagramURL")}</div>
                    <input
                      className="bg-gray-100 rounded-lg w-full p-2 border outline-none"
                      value={userInfo.instagram_url}
                      onChange={(e) =>
                        setuserInfo({
                          ...userInfo,
                          instagram_url: e.target.value,
                        })
                      }
                    />
                  </div>


{/* 

                  <div>
                    <div className="font-medium mb-1">{t("InstagramURL")}</div>
                    <input
                      className="bg-gray-100 rounded-lg w-full p-2 border outline-none"
                      type="text"
                      value={userInfo.instagram_url}
                      onChange={(e) =>
                        setuserInfo({
                          ...userInfo,
                          instagram_url: e.target.value,
                        })
                      }
                    />
                  </div> */}

                  {/* <div>
                    <div className="font-medium mb-1">
                      Upload your legal paper (Passport or ID card)
                    </div>
                    <p className="text-gray-500">
                      * You can request data removal anytime
                    </p>
                    <div className="mt-2 flex justify-center rounded-lg bg-[#F9F9F9] px-6 py-20">
                      <div className="text-center">
                        <div className="mt-4 flex text-base leading-6 text-[#AAAAAA]">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md font-DMsansSemiBold text-palette-watermelon"
                          >
                            <div className="font-DMsansRegular">
                              <CloudUpload
                                className="mx-auto"
                                aria-hidden="true"
                              />
                              <div className="pt-3">Upload a file</div>
                            </div>
                            <input
                              id="file-upload"
                              name="file-upload"
                              type="file"
                              className="sr-only"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div
                  onClick={() => editUser()}
                  className="max-w-lg mx-auto mt-20 mb-10 bg-palette-watermelon p-2 text-center text-white rounded-lg font-medium"
                >
                  {t("Edit")}
                </div>
              </div>
            ) : activeSettingsTab === "ProfitDetails" ? (
              <div className="mx-4">
                <div className="max-w-lg mx-auto space-y-6">
                  <div className="flex  my-5 bg-[#F9F9F9]">
                    <div
                      onClick={() => setactiveBillingType("paypal")}
                      className={`w-full py-2 rounded-lg cursor-pointer my-auto text-center ${
                        activeBillingType === "paypal"
                          ? "bg-palette-watermelon text-white"
                          : "font-medium "
                      }`}
                    >
                      Paypal
                    </div>

                    <div
                      onClick={() => setactiveBillingType("bankTransfer")}
                      className={`w-full py-2 rounded-lg cursor-pointer my-auto text-center ${
                        activeBillingType === "bankTransfer"
                          ? "bg-palette-watermelon text-white"
                          : "font-medium text-gray-600"
                      }`}
                    >
                      {t("BankTransfer")}
                    </div>
                  </div>

                  {activeBillingType === "paypal" ? (
                    <div>
                      <div className="font-medium mb-1">Paypal Email</div>
                      <input
                        className="bg-gray-100 rounded-lg w-full p-2 border outline-none"
                        value={userInfo.paypal_email}
                        onChange={(e) =>
                          setuserInfo({
                            ...userInfo,
                            paypal_email: e.target.value,
                          })
                        }
                      />
                    </div>
                  ) : (
                    <>
                      <div>
                        <div className="font-medium mb-1">IBAN</div>
                        <input
                          className="bg-gray-100 rounded-lg w-full p-2 border outline-none"
                          value={userInfo?.iban_data?.iban}
                          onChange={(e) =>
                            setuserInfo({
                              ...userInfo,
                              iban_data: {
                                ...userInfo.iban_data,
                                iban: e.target.value,
                              },
                            })
                          }
                        />
                      </div>

                      <div>
                        <div className="font-medium mb-1">Swift Code</div>
                        <input
                          className="bg-gray-100 rounded-lg w-full p-2 border outline-none"
                          value={userInfo?.iban_data?.swift}
                          onChange={(e) =>
                            setuserInfo({
                              ...userInfo,
                              iban_data: {
                                ...userInfo.iban_data,
                                swift: e.target.value,
                              },
                            })
                          }
                        />
                      </div>

                      <div>
                        <div className="font-medium mb-1">{t("BankName")}</div>
                        <input
                          className="bg-gray-100 rounded-lg w-full p-2 border outline-none"
                          value={userInfo?.iban_data?.bank}
                          onChange={(e) =>
                            setuserInfo({
                              ...userInfo,
                              iban_data: {
                                ...userInfo.iban_data,
                                bank: e.target.value,
                              },
                            })
                          }
                        />
                      </div>

                      {/* <div>
                        <div className="font-medium mb-1">Bank Branch</div>
                        <input className="bg-gray-100 rounded-lg w-full p-2 border outline-none" />
                      </div> */}
                    </>
                  )}
                </div>
                <div
                  onClick={() => editUser()}
                  className="max-w-lg mx-auto my-10 bg-palette-watermelon p-2 text-center text-white rounded-lg font-medium"
                >
                  {t("Add")}
                </div>
              </div>
            ) : (
              <div className="mx-4">
                <div className="max-w-lg mx-auto space-y-6">
                  <div>
                    <div className="font-medium mb-1">{t("OldPassword")}</div>
                    <div className="flex bg-gray-100 border rounded-lg px-3">
                      <Lock className="my-auto" />

                      <input
                        className="w-full p-3 rounded-lg text-sm outline-none bg-gray-100"
                        placeholder={t("Enter-your-old-password")}
                        value={userInfo.old_password}
                        onChange={(e) =>
                          setuserInfo({
                            ...userInfo,
                            old_password: e.target.value,
                          })
                        }
                      />

                      <Eye className="my-auto text-[#A5A3A9]" />
                    </div>
                  </div>

                  <div>
                    <div className="font-medium mb-1">{t("NewPassword")}</div>
                    <div className="flex bg-gray-100 border rounded-lg px-3">
                      <Lock className="my-auto" />

                      <input
                        className="w-full p-3 rounded-lg text-sm outline-none bg-gray-100"
                        placeholder={t("Enter-your-new-password")}
                        value={userInfo.new_password}
                        onChange={(e) =>
                          setuserInfo({
                            ...userInfo,
                            new_password: e.target.value,
                          })
                        }
                      />

                      <Eye className="my-auto text-[#A5A3A9]" />
                    </div>
                  </div>

                  <div>
                    <div className="font-medium mb-1">
                      {" "}
                      {t("Confirm-new-password")}
                    </div>
                    <div className="flex bg-gray-100 border rounded-lg px-3">
                      <Lock className="my-auto" />

                      <input
                        className="w-full p-3 rounded-lg text-sm outline-none bg-gray-100"
                        placeholder={t("Confirm-new-password")}
                        value={userInfo.confirm_password}
                        onChange={(e) =>
                          setuserInfo({
                            ...userInfo,
                            confirm_password: e.target.value,
                          })
                        }
                      />

                      <Eye className="my-auto text-[#A5A3A9]" />
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => editUser()}
                  className="max-w-lg mx-auto my-10 bg-palette-watermelon p-2 text-center text-white rounded-lg font-medium"
                >
                  {t("ChangePassword")}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <ToastContainer />
    </Layout>
  );
}
