import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Header from "./Header";

export default function Section1V2() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // State to manage the index of the current image
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Arrays of images for desktop and mobile views
  // const desktopImages = ["https://d3c0uohrk36o5d.cloudfront.net/iloveimg-compressed+(1)/sec1.jpg", "https://d3c0uohrk36o5d.cloudfront.net/iloveimg-compressed+(1)/sec2.jpg", "https://d3c0uohrk36o5d.cloudfront.net/iloveimg-compressed+(1)/sec3.jpg", "https://d3c0uohrk36o5d.cloudfront.net/iloveimg-compressed+(1)/sec4.jpg"];
  // const mobileImages = ["https://d3c0uohrk36o5d.cloudfront.net/iloveimg-compressed+(1)/sec1-ph.jpg", "https://d3c0uohrk36o5d.cloudfront.net/iloveimg-compressed+(1)/sec2-ph.jpg", "https://d3c0uohrk36o5d.cloudfront.net/iloveimg-compressed+(1)/sec3-ph.jpg"];

  const desktopImages = ["/sec1.jpg", "/sec2.jpg", "/sec3.jpg", "/sec4.png"];
  const mobileImages = ["/sec1-ph.jpg", "/sec2-ph.jpg", "/sec3-ph.jpg"];

  // State to manage which set of images to use
  const [images, setImages] = useState(desktopImages);

  // Use effect to change the image every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // 5 seconds interval

    return () => clearInterval(interval);
  }, [images.length]);

  // Use effect to switch between desktop and mobile images based on screen size
  useEffect(() => {
    const updateImages = () => {
      if (window.innerWidth <= 768) {
        setImages(mobileImages);
      } else {
        setImages(desktopImages);
      }
    };

    // Initial check
    updateImages();

    // Add event listener to handle window resizing
    window.addEventListener("resize", updateImages);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", updateImages);
  }, []);


  // useEffect(() => {
  //   const preventZoom = (event) => {
  //     if (event.touches.length > 1) {
  //       event.preventDefault();
  //     }
  //   };
    
  //   document.addEventListener('touchmove', preventZoom, { passive: false });
  
  //   return () => {
  //     document.removeEventListener('touchmove', preventZoom);
  //   };
  // }, []);
  
  
  return (
    <div className="h-screen flex justify-center items-center overflow-x-hidden">
      <div className="relative isolate overflow-hidden w-full h-full">
        <Header />
        <div className="absolute inset-0 -z-10 h-full w-full">
          <motion.img
            key={currentImageIndex} // key ensures Framer Motion applies transition when the image changes
            src={images[currentImageIndex]}
            alt=""
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }} // Smooth fade transition
            className="absolute inset-0 h-full w-full object-cover"
          />
        </div>
        <div
          className="absolute inset-0 -z-10 bg-black opacity-70"
          aria-hidden="true"
        ></div>

        <div className="flex justify-center items-center h-full w-full">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2, delay: 0.2 }}
            className="flex justify-center items-center m-auto max-w-2xl px-3 relative z-20"
          >
            <div className="text-center">
              <div className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                {t("TurnMomentsInto")}

                <div className="relative w-fit mx-auto px-10">
                  <label className="font-DMsansExtraBold"> {t("Money")}</label>
                  <svg
                    aria-hidden="true"
                    viewBox="0 0 418 42"
                    className="absolute left-0 top-2/3 h-[0.58em] w-full fill-primary/60 -z-10"
                    preserveAspectRatio="none"
                  >
                    <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
                  </svg>
                </div>
              </div>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                {t("Section1Desc")}
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <button
                  onClick={() => navigate("/upload-and-make-money")}
                  className="rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                >
                  {t("Upload&MakeMoney")}
                </button>
                <button
                  onClick={() => navigate("/login")}
                  className="text-sm font-semibold leading-6 text-white"
                >
                  {t("JoinNow")} <span aria-hidden="true">→</span>
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
