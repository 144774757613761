import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CountUp from "react-countup";

import Header from "../components/module/Landing/Header";
import OurValues from "../components/module/Landing/OurValues";
import FAQ from "../components/module/Landing/FAQ";
import Footer from "../components/module/Landing/Footer";

export default function LandingV2() {
  return (
    <div>
      <Header />
      <Section1 />
      <Stat />
      <OurValues />
      {/* <FAQ /> */}
      <Footer />
    </div>
  );
}

function Stat() {
  const { t } = useTranslation();

  const stats = [
    { id: 1, name: t("total_telewelers"), value: 1000 },
    { id: 2, name: t("total_videos"), value: 5000 },
    { id: 3, name: t("total_views"), value: 5000000 },
  ];
  return (
    <div className="bg-white mt-28">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="text-center">
            <h2 className="text-palette-watermelon font-DMsansExtraBold tracking-tight text-4xl">
              {t("our_numbers")}
            </h2>
            {/* <p className="text-center text-sm md:text-base lg:text-lg font-DMsansRegular my-2 md:my-4">
              {t("our_numbers_desc")}
            </p> */}
          </div>
          <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-3">
            {stats.map((stat) => (
              <div key={stat.id} className="flex flex-col bg-gray-400/5 p-8">
                <dt className="text-base font-DMsansSemiBold leading-6 text-gray-600">
                  {stat.name}
                </dt>

                <dd className="text-palette-watermelon font-DMsansExtraBold order-first text-4xl font-semibold tracking-tight text-gray-900 my-1">
                  +{" "}
                  <CountUp
                    end={stat.value}
                    separator=","
                    // onEnd={({ pauseResume, reset, start, update }) => pauseResume()}
                  />
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

function Section1() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="relative flex flex-col justify-center space-y-6 overflow-hidden ">
      <div className="flex flex-col md:flex-row md:space-x-6 w-full ">
        <div className="mt-10  flex-1 flex-col justify-center items-center  p-4 md:py-10 ">
          <h1 className="hidden lg:block text-palette-watermelon font-DMsansRegular text-center text-3xl md:text-5xl mt-6 md:mt-10">
            {t("TurnMomentsInto")}
            <br />
            <label className="font-DMsansExtraBold"> {t("Money")}</label>
          </h1>
          <h1 className="block lg:hidden text-palette-watermelon font-DMsansRegular text-center text-4xl md:text-3xl mt-10 md:mt-10">
            {t("TurnMomentsInto")}
            <br />
            <label className="font-DMsansExtraBold"> {t("Money")}</label>
          </h1>
          <p className="text-center text-sm md:text-base lg:text-lg font-DMsansRegular my-4 max-w-3xl mx-auto">
            {t("Section1Desc")}
          </p>
          <div className="text-center my-2 md:my-4">
            <button
              onClick={() => navigate("/upload-and-make-money")}
              className="rounded-lg text-white bg-palette-watermelon px-16 md:px-28 py-2"
            >
              {t("Upload&MakeMoney")}!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
