//library imports
import { Routes, Route } from "react-router-dom";

//pages imports
import Landing from "./pages/Landing";
import Blogs from "./pages/Blogs";
import Blog from "./pages/Blog";
import Login from "./pages/Login";
import Terms from "./pages/Terms";
import ContactUs from "./pages/ContactUs";
import UploadMakeMoney from "./pages/UploadMakeMoney";
import Dashboard from "./pages/Dashboard";
import Settings from "./pages/Settings";
import Privacy from "./pages/Privacy";
import AddVideo from "./pages/AddVideo";
import VerificationCode from "./pages/VerifyCode";

import i18next from "i18next";
import io from "socket.io-client";
import { v4 as uuidv4 } from "uuid";

import { useEffect, useState, useRef } from "react";
import LandingV2 from "./pages/LandingV2";

export default function App() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [supportStatus, setSupportStatus] = useState(false);

  const [msgs, setMsgs] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [session_id, setSessionId] = useState("");
  const chatDivRef = useRef(null);

  useEffect(() => {
    setSessionId(uuidv4());
  }, []);

  const handleUserMessage = () => {
    setMsgs((prevChat) => [
      ...prevChat,
      {
        type: "user",
        msg: userInput,
      },
    ]);

    call_socket();
  };

  const call_socket = () => {
    const socketURL = "https://chat.telewee.com";

    const socket = io(socketURL);
    let sentence = "";

    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socket.emit("text_chunk", { chunk: userInput, session_id: session_id }); // Trigger the streaming on the server

    setUserInput("");

    setMsgs((prevChat) => [
      ...prevChat,
      {
        type: "ai",
        msg: "",
      },
    ]);

    socket.on("response_chunk", (chunk) => {
      sentence += chunk;

      setMsgs((prevChat) => {
        prevChat[prevChat.length - 1].msg += chunk;
        return [...prevChat];
      });
    });
  };

  const scrollToBottom = () => {
    if (chatDivRef.current) {
      chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [msgs]);

  return (
    <div>
      {!supportStatus ? (
        <div
          class="relative "
          onClick={(e) => {
            setSupportStatus(true);
          }}
        >
          <button
            class="drop-shadow-md z-20 text-white flex flex-col shrink-0 grow-0 justify-around 
                  fixed bottom-0 right-0 right-1 rounded-lg
                  mr-1 mb-5 lg:mr-5 lg:mb-5 xl:mr-10 xl:mb-10"
          >
            <div class="p-1 rounded-full border-4 border-white bg-gray-50">
              <img
                class="w-10 h-10 lg:w-12 lg:h-12 xl:w-16 xl:h-16"
                src="icons8-support-64.png"
              />
            </div>
          </button>
        </div>
      ) : (
        <div class="fixed inset-0 z-50 bg-white bg-opacity-50">
          <div class="fixed bottom-0 right-0">
            <div class="flex space-x-4">
              <div class="w-80 h-96 flex flex-col border shadow-md bg-white rounded">
                <div class="flex items-center justify-between border-b p-2">
                  <div class="flex items-center">
                    <div
                      class="pl-2"
                      onClick={(e) => {
                        setSupportStatus(false);
                      }}
                    >
                      <div class="">
                        <label>Telewee Support</label>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={(e) => {
                      setSupportStatus(false);
                    }}
                  >
                    <button
                      class="inline-flex hover:bg-primary rounded-full p-2"
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <div class="flex-1 px-4 py-4 overflow-y-auto" ref={chatDivRef}>
           

                  {msgs.map((item, idx) =>
                    item.type == "user" ? (
                      <div class="flex items-center mb-4">
                        <div class="flex-none flex flex-col items-center space-y-1 mr-4">
                          <img
                            class="rounded-full w-10 h-10"
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6gIYDzZ_61tHtMJgKWiXwpSYRSwzucK_bqmQpw7pc9Q&s"
                          />
                          <a href="#" class="block text-xs hover:underline">
                            You
                          </a>
                        </div>
                        <div class="flex-1 bg-primary text-white p-2 rounded-lg mb-2 relative">
                          <div>{item.msg}</div>

                          <div class="absolute left-0 top-1/2 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-primary"></div>
                        </div>
                      </div>
                    ) : (
                      <div class="flex items-center flex-row-reverse mb-4">
                        <div class="flex-none flex flex-col items-center space-y-1 ml-4">
                          <img
                            class="rounded-full w-10 h-10"
                            src="icons8-support-64.png"
                          />
                          <a href="#" class="block text-xs hover:underline">
                            AI Agent
                          </a>
                        </div>
                        <div class="flex-1 bg-gray-100 text-gray-800 p-2 rounded-lg mb-2 relative">
                          <div>{item.msg}</div>

                          <div class="absolute right-0 top-1/2 transform translate-x-1/2 rotate-45 w-2 h-2 bg-gray-100"></div>
                        </div>
                      </div>
                    )
                  )}
                </div>

                <div class="flex items-center border-t p-2">
                  <div class="w-full mx-2">
                    <textarea
                      // cols={2}
                      rows={2}
                      onChange={(e) => {
                        setUserInput(e.target.value);
                      }}
                      value={userInput}
                      class="p-1 pl-2 w-full rounded border border-gray-200"
                      type="text"
                      placeholder="Aa"
                      autofocus
                    />
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        handleUserMessage();
                      }}
                      class="inline-flex hover:bg-primary rounded-full p-2"
                      type="button"
                    >
                 

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Routes>
        <Route path="/" element={user ? <Dashboard /> : <Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        {/* <Route path="/privacy_policy" element={<Privacy />} /> */}
        
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:slug" element={<Blog />} /> {/* Add this line */}
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/upload-and-make-money" element={<UploadMakeMoney />} />
        <Route path="/add-video" element={<AddVideo />} />
        <Route path="/verification-code" element={<VerificationCode />} />
        <Route path="/telewee-landing" element={<LandingV2 />} />
      </Routes>
    </div>
  );
}
