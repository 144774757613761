import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";

import LogoImg from "../../../assets/Logo.png";

export default function Footer() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div dir={currentLanguageCode === "ar" ? "rtl" : "ltr"}>
      <div className="grid grid-cols-1 lg:grid-cols-5 w-full bg-[#FAFAFA] px-5 lg:px-36 py-12">
        <div className="space-y-3 mb-5 lg:mb-0">
          <img
            onClick={() => navigate("/")}
            src={LogoImg}
            className="w-[195px] h-[41px] object-cover my-auto cursor-pointer"
          />
          <p className="font-DMsansRegular">{t("footerDesc")}</p>
        </div>

        <div className="m-0 lg:mx-auto space-y-3 mb-5 lg:mb-0">
          <div className="text-palette-watermelon font-DMsansSemiBold">
            {t("About")} <br /> Telewee
            <div className="h-1 w-6 bg-palette-watermelon rounded-full mt-2" />
          </div>
          <p
            className="font-DMsansRegular cursor-pointer"
            onClick={() => navigate("/contact-us")}
          >
            {t("ContactUs")}
          </p>
        </div>

        <div className="space-y-3 mb-5 lg:mb-0">
          <div className="text-palette-watermelon font-DMsansSemiBold">
            {t("HowItWorks")}
            <div className="h-1 w-6 bg-palette-watermelon rounded-full mt-2" />
          </div>
          <p
            onClick={() => navigate("/upload-and-make-money")}
            className="font-DMsansRegular cursor-pointer"
          >
            {t("Upload&MakeMoney")}!
          </p>
        </div>
        <div className="space-y-3 mb-5 lg:mb-0">
          <div className="text-palette-watermelon font-DMsansSemiBold">
            {t("OurPolices")}
            <div className="h-1 w-6 bg-palette-watermelon rounded-full mt-2" />
          </div>
          <p
            className="font-DMsansRegular cursor-pointer"
            onClick={() => navigate("/terms-and-conditions")}
          >
            {t("Terms&Conditions")}
          </p>
          <p
            className="font-DMsansRegular cursor-pointer"
            onClick={() => navigate("/privacy-policy")}
          >
            {t("privacy_policy")}
          </p>
        </div>
        <div className="space-y-3 ">
          <div className="text-palette-watermelon font-DMsansSemiBold">
            {t("NeedHelp")}
            <div className="h-1 w-6 bg-palette-watermelon rounded-full mt-2" />
          </div>
          {/* <div>
            <h3 className="font-DMsansBold">CALL US</h3>
            <p>+XX XXX XXXXXXX</p>
          </div> */}
          <div>
            <h3 className="font-DMsansBold">EMAIL US</h3>
            <a href="mailto:support@telewee.com">support@telewee.com</a>
          </div>
        </div>
      </div>
      <div className="bg-palette-watermelon text-white text-center py-2 font-DMsansMedium">
        Telewee © {new Date().getFullYear()} - {t("AllRightsReserved")}
      </div>
    </div>
  );
}
