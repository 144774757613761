// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/ArrowRight.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/ArrowLeft.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper-button-next {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  height: 50px !important;
  width: 50px !important;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  /* transform: rotate(180deg); */
  height: 50px !important;
  width: 50px !important;
}

.swiper-button-prev::after {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/swipeableVideo.css"],"names":[],"mappings":"AAAA;EACE,yDAAgD;EAChD,4BAA4B;EAC5B,0BAA0B;EAC1B,2BAA2B;EAC3B,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,yDAA+C;EAC/C,4BAA4B;EAC5B,0BAA0B;EAC1B,2BAA2B;EAC3B,+BAA+B;EAC/B,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".swiper-button-next {\n  background-image: url(\"./assets/ArrowRight.svg\");\n  background-repeat: no-repeat;\n  background-size: 100% auto;\n  background-position: center;\n  height: 50px !important;\n  width: 50px !important;\n}\n\n.swiper-button-next::after {\n  display: none;\n}\n\n.swiper-button-prev {\n  background-image: url(\"./assets/ArrowLeft.svg\");\n  background-repeat: no-repeat;\n  background-size: 100% auto;\n  background-position: center;\n  /* transform: rotate(180deg); */\n  height: 50px !important;\n  width: 50px !important;\n}\n\n.swiper-button-prev::after {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
