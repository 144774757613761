import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";

import { ReactComponent as Menu } from "../../../assets/Menu.svg";
import { ReactComponent as ChevronDownIcon } from "../../../assets/ChevronDownIcon.svg";
import { ReactComponent as LangIcon } from "../../../assets/Lang.svg";
import LogoImg from "../../../assets/Logo.png";

export default function Header() {
  const navigate = useNavigate();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("user"));

  let navigation = [
    {
      name: t("Terms"),
      href: "/terms-and-conditions",
    },
    {
      name: t("blogs"),
      href: "/blogs",
    },

    {
      name: t("ContactUs"),
      href: "/contact-us",
    },
    {
      name: t("Upload&MakeMoney"),
      href: "/upload-and-make-money",
    },
  ];

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div>
      <div className="flex justify-between bg-[#FAFAFA] py-5 px-5 lg:px-36">
        <div onClick={() => navigate("/")}>
          <img
            src={LogoImg}
            className="w-[195px] h-[41px] object-cover my-auto cursor-pointer"
          />
        </div>
        <div className="hidden lg:flex space-x-8 my-auto font-DMsansMedium">
          {navigation.map((item, idx) => {
            return (
              <div
                key={idx}
                onClick={() => navigate(item.href)}
                className="cursor-pointer"
              >
                {item.name}
              </div>
            );
          })}
        </div>

        <div className="hidden lg:flex my-auto space-x-3">
          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={`
                ${open ? "text-white" : "text-white/90"}
                group inline-flex space-x-2 items-center rounded-md bg-palette-watermelon text-white rounded-lg py-2 px-4 text-base font-medium`}
                >
                  <LangIcon />
                  <ChevronDownIcon className="h-3 w-3" />
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-52 -translate-x-1/2 transform px-4">
                    <div
                      dir={currentLanguageCode === "ar" ? "rtl" : "ltr"}
                      className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5"
                    >
                      <div className="bg-white p-2">
                        <div
                          onClick={() => i18next.changeLanguage("en")}
                          className="hover:bg-gray-100 rounded-lg p-2 mb-3  text-sm font-SomarMedium text-[#718096]"
                        >
                          {t("English")}
                        </div>

                        <div
                          onClick={() => i18next.changeLanguage("ar")}
                          className="hover:bg-gray-100 rounded-lg p-2  text-sm font-SomarMedium text-[#718096]"
                        >
                          {t("Arabic")}
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>

          <button
            onClick={() =>
              userData ? navigate("/dashboard") : navigate("/login")
            }
            href="#"
            className="text-sm font-semibold leading-6"
          >
            {userData ? t("myAccount") : t("JoinNow")}{" "}
            <span aria-hidden="true">→</span>
          </button>
        </div>

        <button
          onClick={() => setSidebarOpen(!sidebarOpen)}
          className="flex lg:hidden space-x-3 my-auto z-50"
        >
          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={`
                ${open ? "text-white" : "text-white/90"}
                group inline-flex space-x-2 items-center rounded-md bg-palette-watermelon text-white rounded-lg p-2 text-base font-medium hover:text-white `}
                >
                  <LangIcon className="h-4 w-4" />
                  <ChevronDownIcon className="h-3 w-3" />
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute left-0 z-10 mt-3 w-52 -translate-x-1/2 transform px-4">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                      <div className="bg-white p-2">
                        <div
                          onClick={() => i18next.changeLanguage("en")}
                          className="hover:bg-gray-100 rounded-lg p-2 mb-3  text-sm font-SomarMedium text-[#718096]"
                        >
                          {t("English")}
                        </div>

                        <div
                          onClick={() => i18next.changeLanguage("ar")}
                          className="hover:bg-gray-100 rounded-lg p-2  text-sm font-SomarMedium text-[#718096]"
                        >
                          {t("Arabic")}
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>

          <Menu className="my-auto" />
        </button>
      </div>
      {sidebarOpen ? (
        <div className="bg-white border-b w-full z-50">
          <div className="flex-1 pt-5 pb-4">
            <nav className="mt-5 space-y-4">
              {navigation.map((item) => (
                <div
                  key={item.name}
                  onClick={() => navigate(item.href)}
                  className="group text-black hover:bg-gray-100 flex items-center py-2 text-base font-medium flex justify-center mx-auto "
                >
                  {item.name}
                </div>
              ))}
              {/* <button
                onClick={() => {
                  navigate("/login");
                }}
                className="bg-palette-watermelon text-white rounded-lg mt-5 py-2 px-4 flex justify-center mx-auto z-50"
              >
                {t("JoinNow")}
              </button> */}

              <button
                onClick={() =>
                  userData ? navigate("/dashboard") : navigate("/login")
                }
                className="bg-palette-watermelon text-white rounded-lg mt-5 py-2 px-4 flex justify-center mx-auto z-50"
              >
                {userData ? t("myAccount") : t("JoinNow")}
              </button>
            </nav>
          </div>
        </div>
      ) : null}
    </div>
  );
}
