import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import { motion } from "framer-motion";

import { ReactComponent as Monetize } from "../../../assets/Monetize.svg";
import { ReactComponent as Protect } from "../../../assets/Protect.svg";
import { ReactComponent as GlobalExposure } from "../../../assets/GlobalExposure.svg";

export default function OurValues() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t } = useTranslation();

  return (
    <div className="mt-20">
      <h1 className="text-palette-watermelon font-DMsansExtraBold text-center text-4xl mb-4">
        {t("OurValues")}
      </h1>

      <p className="mx-10 lg:mx-auto text-center text-base lg:text-lg max-w-3xl font-DMsansRegular">
        {t("OurValuesDesc")}
      </p>

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1, delay: 0 }}
        variants={{
          hidden: { x: 200, opacity: 0 },
          visible: { x: 0, opacity: 1 },
        }}
      >
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 lg:px-52 px-5 my-10">
          <div className="bg-[#F9F9F9F9] px-14 py-4 rounded-xl shadow-sm border border-[#F9F9F9]">
            <Monetize className="mx-auto mb-3" />
            <h1 className="text-palette-watermelon font-DMsansExtraBold text-center text-2xl">
              {t("Monetize")}
            </h1>
            <p className="text-center text-2xl font-DMsansRegular">
              {t("yourContent")}
            </p>
          </div>

          <div className="bg-[#F9F9F9F9] px-14 py-4 rounded-xl shadow-sm border border-[#F9F9F9]">
            <Protect className="mx-auto mb-3" />
            <h1 className="text-palette-watermelon font-DMsansExtraBold text-center text-2xl">
              {t("Protect")}
            </h1>
            <p className="text-center text-2xl font-DMsansRegular">
              {t("yourContentAndVideos")}
            </p>
          </div>

          <div className="bg-[#F9F9F9F9] px-14 py-4 rounded-xl shadow-sm border border-[#F9F9F9]">
            <GlobalExposure className="mx-auto mb-3" />
            <p className="text-center text-2xl font-DMsansRegular">
              {t("Get")}
            </p>
            <h1 className="text-palette-watermelon font-DMsansExtraBold text-center text-2xl">
              {t("globalExposure")}
            </h1>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
