import { useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import i18next, { use } from "i18next";
import cookies from "js-cookie";

import { loginAPI,verifyPhoneAPI,verifyCodeAPI,updatePasswordAPI } from "../services/apis/User";

import { ReactComponent as Email } from "../assets/login/Email.svg";
import { ReactComponent as Lock } from "../assets/login/Lock.svg";
import { ReactComponent as Eye } from "../assets/login/Eye.svg";
import { ReactComponent as LoginImg } from "../assets/login/Login.svg";
import LogoImg from "../assets/Logo.png";

export default function VerificationCode() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [userId, setUserId] = useState("");
  const [mode, setMode] = useState("login");

  const [code, setCode] = useState("");


  async function verifyCode() {
  let phone_number = location.state.phone_number;

    if (phone_number !== "" && code !== "") {
      await verifyCodeAPI({phone_number: phone_number, code:code})
        .then((result) => {
          localStorage.setItem("user", JSON.stringify(result));
         setTimeout(() => {
            navigate("/dashboard");
         }, 200);
        //   setUserId(result._id)
        //   setMode("forgot3")
        })
        .catch((err) => {
          alert("Code is wrong")
        });

    } else {
      alert("Please make sure to fill all fields")
    }
  }


 

  return (
    <div
      dir={currentLanguageCode === "ar" ? "rtl" : "ltr"}
      className="bg-white h-full lg:h-screen grid grid-cols-1 lg:grid-cols-5 overflow-hidden"
    >
      <div className="my-10 mx-auto lg:m-auto lg:col-span-2">
        <img
          src={LogoImg}
          onClick={() => (window.location.href = "/")}
          className="w-[300px] h-[55px] object-cover my-auto cursor-pointer mx-auto lg:mb-10 "
        />

        <LoginImg className="w-[300px] h-[300px] lg:h-[424.11px] lg:w-[419.26px]" />
      </div>


      <div className="lg:col-span-3 bg-[#f9f9f9] px-5 lg:px-28 py-14 lg:py-52">
<div className="mb-10">
  <h3 className="text-4xl font-bold ">
    {t("WelcomeTo")}{" "}
    <span className="text-palette-watermelon">Telewee</span>
  </h3>
  <p className="text-palette-watermelon font-bold text-base mt-2">
    {/* {t("SignInToYourAccount")} */}
    {t("verification_code")}
  </p>
</div>

<div className="mb-5">
  <label className="mb-2 font-medium text-primary">{t("enter_the_verficiation_code")}</label>
  <div className="flex bg-white border rounded-lg ">
    <input
      className="w-full p-3 rounded-lg text-sm outline-none"
      placeholder={t("****")}
      type="number"
      value={code}
      onChange={(e) =>{
        setCode(e.target.value)
      }
      }
    />
  </div>
</div>



<button
  onClick={() => {
    verifyCode()
  }}
  className={`${
       "bg-palette-watermelon"
  } font-medium w-full p-3 text-white rounded-lg mb-3`}
>
  {t("Login")}
</button>


</div>


        <ToastContainer />
    
    </div>
  );
}
