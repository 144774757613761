import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import React, { useState, useRef } from "react";
import { ReactComponent as Play } from "../../../assets/Play.svg";
import SwipeableVideoSlider from "./SwipeableVideoSlider";

export default function Trending() {
  const { t } = useTranslation();

  const videos = [
    {
      video: "https://d3c0uohrk36o5d.cloudfront.net/dqlEXSrqa.mov",
      img: "https://thispersondoesnotexist.com/",
      user: "Johnny killy",
      title: "Software engineer",
      type: "Family video",
    },
    {
      video: "https://d3c0uohrk36o5d.cloudfront.net/G0jeL5Mmz.mov",
      img: "https://thispersondoesnotexist.com/",
      user: "Johnny killy",
      title: "Software engineer",
      type: "Family video",
    },
    {
      video: "https://d3c0uohrk36o5d.cloudfront.net/jjzyhG4vk.mp4",
      img: "https://thispersondoesnotexist.com/",
      user: "Johnny killy",
      title: "Software engineer",
      type: "Family video",
    },
    {
      video: "https://d3c0uohrk36o5d.cloudfront.net/RzjukBcD5.mp4",
      img: "https://thispersondoesnotexist.com/",
      user: "Johnny killy",
      title: "Software engineer",
      type: "Family video",
    },
  ];

  return (
    <div className="relative mt-28">
      <h1 className="text-palette-watermelon font-DMsansExtraBold text-center text-4xl mb-4">
        {t("trending_videos")}
      </h1>

      <p className="mx-10 lg:mx-auto text-center text-base lg:text-lg max-w-4xl font-DMsansRegular">
        {t("trending_videos_desc")}{" "}
      </p>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1, delay: 0 }}
        variants={{
          hidden: { x: -200, opacity: 0 },
          visible: { x: 0, opacity: 1 },
        }}
      >
        <div className="my-5 block lg:hidden">
          <SwipeableVideoSlider videos={videos} />
        </div>

        <div className="my-5 hidden lg:block">
          <div className="grid grid-cols-4 gap-4 my-5 px-5 lg:px-36">
            {videos.map((video, idx) => {
              return (
                <div key={idx}>
                  <SmallVideoComp video={video} />
                </div>
              );
            })}
          </div>
        </div>
      </motion.div>
    </div>
  );
}

function SmallVideoComp({ video }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handleVideoClick = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className="relative">
      <div className="w-full h-[445px] shadow">
        <div
          style={{
            overflow: "hidden",
          }}
          className="relative w-full h-[445px] rounded-t-xl"
        >
          <video
            ref={videoRef}
            onClick={handleVideoClick}
            className="w-full h-full object-cover rounded-xl"
            style={{
              objectFit: "cover", // This will crop the video to fit the container
              width: "100%", // Ensure the video takes the full width of the container
              height: "100%", // Ensure the video takes the full height of the container
            }}
          >
            <source src={video.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {!isPlaying && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                cursor: "pointer",
              }}
              onClick={handleVideoClick}
            >
              {/* You can use any play icon here */}
              <Play className="h-20 w-20 lg:w-32 lg:h-32" />
            </div>
          )}

          {/* <div className="absolute bottom-0 bg-white w-full py-2 rounded-tl-xl">
            <div className="flex my-auto">
              <img
                src={video.img}
                className="h-14 w-14 rounded-full mx-2"
                alt="user"
              />
              <div>
                <h4 className="font-DMsansSemiBold">{video.user}</h4>
                <h4 className="font-DMsansRegular">{video.title}</h4>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
