import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import i18next, { use } from "i18next";
import cookies from "js-cookie";

import { loginAPI,verifyPhoneAPI,verifyCodeAPI,updatePasswordAPI } from "../services/apis/User";

import { ReactComponent as Email } from "../assets/login/Email.svg";
import { ReactComponent as Lock } from "../assets/login/Lock.svg";
import { ReactComponent as Eye } from "../assets/login/Eye.svg";
import { ReactComponent as LoginImg } from "../assets/login/Login.svg";
import LogoImg from "../assets/Logo.png";
import PhoneField from "../components/PhoneField";



export default function Login() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [user, setuser] = useState({ phone_number: "", password: "",code:"",confirmed_password:"" });
  const [userId, setUserId] = useState("");
  const [mode, setMode] = useState("login");

  async function login() {
    if (user.phone_number !== "" && user.password !== "") {
      await loginAPI(user)
        .then((result) => {
          localStorage.setItem("user", JSON.stringify(result));
          navigate("/dashboard");
        })
        .catch((err) => {
          alert("Phone number or password is wrong!")
        });

    } else {
      alert("Please make sure to fill all fields")
    }
  }



  async function verifyPhone() {
    if (user.phone_number !== "") {
      await verifyPhoneAPI({phone_number: user.phone_number})
        .then((result) => {
          // localStorage.setItem("user", JSON.stringify(result));
          // navigate("/dashboard");
          setMode("forgot2")
        })
        .catch((err) => {
          alert("Phone number is wrong")
        });

    } else {
      alert("Please make sure to fill all fields")
    }
  }


  async function verifyCode() {
    if (user.phone_number !== "" && user.code !== "") {
      await verifyCodeAPI({phone_number: user.phone_number, code:user.code})
        .then((result) => {
          // localStorage.setItem("user", JSON.stringify(result));
          // navigate("/dashboard");
          setUserId(result._id)
          setMode("forgot3")
        })
        .catch((err) => {
          alert("Code is wrong")
        });

    } else {
      alert("Please make sure to fill all fields")
    }
  }


  async function changePassword() {
    if (userId !== "" && user.confirmed_password !== "" && user.password)  {
      if(user.confirmed_password==user.password)
      await updatePasswordAPI({password: user.password, code:user.code, user: userId})
        .then((result) => {
          // localStorage.setItem("user", JSON.stringify(result));
          // navigate("/dashboard");
          alert("You have changed your password sucessfully")
          setMode("login")
        })
        .catch((err) => {
          alert("Code is wrong")
        });
        else
        alert("Passwords are not matched")

    } else {
      alert("Please make sure to fill all fields")
    }
  }





  return (
    <div
      dir={currentLanguageCode === "ar" ? "rtl" : "ltr"}
      className="bg-white h-full lg:h-screen grid grid-cols-1 lg:grid-cols-5 overflow-hidden"
    >
      <div className="my-10 mx-auto lg:m-auto lg:col-span-2">
        <img
          src={LogoImg}
          onClick={() => (window.location.href = "/")}
          className="w-[300px] h-[55px] object-cover my-auto cursor-pointer mx-auto lg:mb-10 "
        />

        <LoginImg className="w-[300px] h-[300px] lg:h-[424.11px] lg:w-[419.26px]" />
      </div>



{
  mode=='login'?
  <div className="lg:col-span-3 bg-[#f9f9f9] px-5 lg:px-28 py-14 lg:py-52">
        <div className="mb-20">
          <h3 className="text-4xl font-bold ">
            {t("WelcomeTo")}{" "}
            <span className="text-palette-watermelon">Telewee</span>
          </h3>
          <p className="text-palette-watermelon font-bold text-base mt-2">
            {t("SignInToYourAccount")}
          </p>
        </div>

        <div className="mb-5">
          <label className="mb-2 font-medium text-primary">{t("Phone")}</label>
          <div className="flex bg-white border rounded-lg ">
            {/* <input
              className="w-full p-3 rounded-lg text-sm outline-none"
              placeholder={t("EnterYourPhone")}
              type="tel"
              id="phone"
              name="phone"
              value={user.phone_number}
              onChange={(e) =>
                setuser({ ...user, phone_number: e.target.value })
              }
            /> */}


<PhoneField
                      value={user.phone_number}
                      onChange={(e) =>{
                        setuser({ ...user, phone_number: e })
                      }
                      }
                      placeholder={t("Enter-your-phone-number")}
              className="w-full p-3 rounded-lg text-sm outline-none"

                    />

          </div>
        </div>

        <div>
          
          <label className="mb-2 font-medium text-primary">
            {t("Password")}
          </label>
          <div className="flex bg-white border rounded-lg">
          {/* <Lock className="my-auto" /> */}
            <input
              className="w-full p-3  rounded-lg text-sm outline-none"
              placeholder={t("EnterYourPassword")}
              type="password"
              value={user.password}
              onChange={(e) => setuser({ ...user, password: e.target.value })}
            />

          </div>
        </div>

        <div className="mb-14 mt-2 flex justify-between">
          <div className="relative flex gap-x-3">
            <div className="flex h-6 items-center">
              <input
                id="RememberMe"
                name="RememberMe"
                type="checkbox"
                className="h-4 w-4 rounded-xl border-gray-100 "
              />
            </div>
            <label className="text-sm leading-6 text-gray-500">
              {t("RememberMe")}
            </label>
          </div>

          <div
          onClick={((e)=>setMode("forgot1"))}
          className="text-palette-watermelon font-regular text-sm cursor-pointer">
            {t("forgotPassword")}
          </div>
        </div>

        <button
          onClick={() => {
            login();
          }}
          className={`${
            user.phone_number && user.password
              ? "bg-palette-watermelon"
              : "bg-gray-400"
          } font-medium w-full p-3 text-white rounded-lg mb-3`}
        >
          {t("Login")}
        </button>

        <p className="text-center text-sm text-gray-500">
          {t("DontHaveAnAccount")}{" "}
          <a
            href="/upload-and-make-money"
            className="font-semibold leading-6 text-palette-watermelon "
          >
            {t("SignUpNow")}
          </a>
        </p>

      
      </div>
      
      :

mode=='forgot1'? // put phone number

<div className="lg:col-span-3 bg-[#f9f9f9] px-5 lg:px-28 py-14 lg:py-52">
<div className="mb-10">
  <h3 className="text-4xl font-bold ">
    {t("WelcomeTo")}{" "}
    <span className="text-palette-watermelon">Telewee</span>
  </h3>
  <p className="text-palette-watermelon font-bold text-base mt-2">
    {t("forgot_password")}
  </p>
</div>

<div className="mb-5">
  <label className="mb-2 font-medium text-primary">{t("EnterYourPhone")}</label>
  <div className="flex bg-white border rounded-lg ">
    {/* <input
      className="w-full p-3 rounded-lg text-sm outline-none"
      placeholder={t("+962********")}
      type="tel"
      id="phone"
      name="phone"
      value={user.phone_number}
      onChange={(e) =>
        setuser({ ...user, phone_number: e.target.value })
      }
    /> */}


<PhoneField
                    className={"w-full p-3 rounded-lg text-sm outline-none"}
                      value={user.phone_number}
                      onChange={(e) =>{
                        setuser({ ...user, phone_number: e })
                      }
                      }
                      placeholder={t("Enter-your-phone-number")}
                    />

  </div>
</div>


<button
  onClick={() => {
    verifyPhone()
  }}
  className={`${
    user.phone_number && user.password
      ? "bg-palette-watermelon"
      : "bg-gray-400"
  } font-medium w-full p-3 text-white rounded-lg mb-3`}
>
  {t("next")}
</button>


{/* <ToastContainer /> */}
</div>:

mode=='forgot2'? // put code

<div className="lg:col-span-3 bg-[#f9f9f9] px-5 lg:px-28 py-14 lg:py-52">
<div className="mb-10">
  <h3 className="text-4xl font-bold ">
    {t("WelcomeTo")}{" "}
    <span className="text-palette-watermelon">Telewee</span>
  </h3>
  <p className="text-palette-watermelon font-bold text-base mt-2">
    {/* {t("SignInToYourAccount")} */}
    {t("verification_code")}
  </p>
</div>

<div className="mb-5">
  <label className="mb-2 font-medium text-primary">{t("enter_the_verficiation_code")}</label>
  <div className="flex bg-white border rounded-lg ">
    <input
      className="w-full p-3 rounded-lg text-sm outline-none"
      placeholder={t("****")}
      type="number"
      value={user.code}
      onChange={(e) =>
        setuser({ ...user, code: e.target.value })
      }
    />
  </div>
</div>



<button
  onClick={() => {
    verifyCode()
  }}
  className={`${
    user.phone_number && user.password
      ? "bg-palette-watermelon"
      : "bg-gray-400"
  } font-medium w-full p-3 text-white rounded-lg mb-3`}
>
  {t("next")}
</button>


</div>

:
mode=='forgot3'?
<div className="lg:col-span-3 bg-[#f9f9f9] px-5 lg:px-28 py-14 lg:py-52">
<div className="mb-10">
  <h3 className="text-4xl font-bold ">
    {t("WelcomeTo")}{" "}
    <span className="text-palette-watermelon">Telewee</span>
  </h3>
  <p className="text-palette-watermelon font-bold text-base mt-2">
    {t("new_password")}
  </p>
</div>


<div>
  <label className="font-medium text-primary">
    {t("Password")}
  </label>
  <div className="flex bg-white border rounded-lg px-3">
  <Lock className="my-auto" />

    <input
      className="w-full p-3 rounded-lg text-sm outline-none"
      placeholder={t("EnterYourPassword")}
      type="password"
      value={user.password}
      onChange={(e) => setuser({ ...user, password: e.target.value })}
    />

    {/* <Eye className="my-auto text-[#A5A3A9]" /> */}
  </div>


  
</div>



<div className="mt-4 mb-4">
  <label className=" font-medium text-primary">
    {t("confirm_password")}
  </label>
  <div className="flex bg-white border rounded-lg px-3">
    <Lock className="my-auto" />

    <input
      className="w-full p-3 rounded-lg text-sm outline-none"
      placeholder={t("enter_confirm_password")}
      type="password"
      value={user.confirmed_password}
      onChange={(e) => setuser({ ...user, confirmed_password: e.target.value })}
    />

  </div>
</div>



<button
  onClick={() => {
    changePassword();
  }}
  className={`${
    user.phone_number && user.password
      ? "bg-palette-watermelon"
      : "bg-gray-400"
  } font-medium w-full p-3 text-white rounded-lg mb-3`}
>
  {t("Login")}
</button>

</div>
:null



}
        <ToastContainer />
    
    </div>
  );
}
