import { ReactComponent as TermsIcon } from "../assets/Terms.svg";
import { ReactComponent as TermsQuestions } from "../assets/TermsQuestions.svg";
import Footer from "../components/module/Landing/Footer";
import Header from "../components/module/Landing/Header";

export default function Terms() {
  return (
    <div>
      <Header />
      <div className="bg-[#FAFAFA]">
        <div className="max-w-6xl mx-auto">
          <div className="flex flex-col lg:flex-row space-x-7 py-10">
            <TermsIcon className="h-[218px] w-[264.42px] mx-auto lg:mx-0 lg:w-[402.7px] lg:h-[332px]" />
            <h1 className="text-5xl lg:text-7xl grow my-auto font-DMsansBold text-palette-watermelon tracking-widest mt-5 lg:mt-0">
              Terms &<br /> conditions
            </h1>
          </div>
        </div>
      </div>

      <div className="max-w-6xl mx-auto my-10 px-5 lg:px-0">
        <h1 className="text-2xl font-DMsansSemiBold mb-2">
          Effective Date: 31/01/2024
        </h1>
        <p className="font-DMsansRegular mb-2">
          Welcome to Telewee.com (“Website”)! These Terms and Conditions
          ("Terms") govern your access and use of the Website and associated
          services. Please read these Terms carefully before accessing or using
          the Website as these Terms form a legally binding contract between you
          and [owner of the website]. By accessing or using our website and
          services, you agree to be bound by these Terms.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">1. Eligibility</h2>
        <p className="font-DMsansRegular mb-2">
          • You must be at least eighteen (18) years old or the legal age in
          your jurisdiction to register an account, use, and/or access our
          services. If you are under the applicable age you may access and use
          the Website only through a parent or legal guardian’s account and
          under their supervision. By using this website, you represent and
          warrant that you meet this eligibility requirement. • If you are using
          our services on behalf of an entity/organization, you represent and
          warrant that you have the necessary authority to bind that
          entity/organization to these Terms otherwise do not access or use the
          website. • By utilizing our Services, you represent, warrant, and
          agree to the following: • You have the capacity to enter into a
          legally binding contract with Telewee. • You are not prohibited from
          using the Services under the laws of the United Arab Emirates or any
          other relevant jurisdiction. • You are not a convicted sex assault or
          offender. • You are not a terror or hate group or a member thereof. •
          You will adhere to these Terms, including any other terms and policies
          mentioned within these Terms, such as the Privacy Policy, Content
          License Agreement, Personal Data form, Content Partner, as well as all
          relevant local, state, national, and international laws, rules, and
          regulations. • If it comes to our knowledge that you fall below this
          age threshold, we will suspend your access to the Website and
          Services, and suspend or delete your account and data. other terms may
          apply to different Services that require a higher age requirement.
          Please carefully review these terms when prompted.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">2. Our Services</h2>
        <p className="font-DMsansRegular mb-2">
          Subject to the conditions outlined in these Terms, we authorize your
          use of our Services. This authorization includes the following
          Services: • Streaming videos for which you possess proper viewing
          rights. • Uploading, storing, and/or live streaming videos, based on
          your subscribed plan. • Integrating our embeddable video player into
          external websites. • Utilizing any associated features we offer. •
          Monetization of submitted content through our Content Partner
          Agreement.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">
          3. Registration of an Account
        </h2>
        <p className="font-DMsansRegular mb-2">
          • In order to access specific Services, it's necessary for you to set
          up an account. You are obligated to provide us with correct, true,
          accurate, and up-to-date account information. You bear responsibility
          for any activity conducted within your account, making it imperative
          to maintain its security. A recommended practice to enhance your
          account's security is to choose a strong, unique password not utilized
          for any other accounts, and to enable two-factor authentication. If
          you suspect unauthorized access to your account, promptly contact
          Support. Any software we provide may automatically retrieve and
          install upgrades, updates, or new features. You might have the option
          to adjust these automatic downloads in your device settings. You also
          agree not to create an account if we have previously removed or banned
          you or your account from any of our Services unless we grant
          permission. In summary, ensure the safety and security of your account
          details and only use an account when authorized by us. • To obtain
          access to the Website and to uphold a Telewee Account, you are obliged
          to fill in the required information as outlined in the Personal Data
          Form available at the Website otherwise your request to access will be
          rejected. • You are solely responsible for maintaining the
          confidentiality of your account credentials and agree not to share
          your account with others.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">
          4. Rights Granted to the Website
        </h2>
        <p className="font-DMsansRegular mb-2">
          • Your content. By posting or submitting content to the Website, you
          grant [Telewee] a license and permission to: - stream, make the
          content available for download, and share your content with end users.
          - promote and distribute the content through our Content License
          Agreement. - alter, modify, develop, or omit any content and/or any
          part thereof. • Your feedback. You are welcome to share your comments,
          suggestions, or any other feedback regarding our Services. By doing
          so, you grant Telewee a non-exclusive, worldwide, royalty-free license
          to use irrevocable right, license, and permission to use, transmit,
          copy distribute, publicly present, share, and display that feedback in
          any way, without the obligation to provide any compensation to you on
          a perpetual basis. All the licenses granted under this section are
          non-exclusive, worldwide, royalty-free licenses to use, reproduce,
          modify, adapt, and distribute the content for the purpose of operating
          and promoting the services. If you choose to monetize your content
          then you will be also subject to the terms and conditions of our
          Content Partner Agreement available on the Website.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">
          5. Content Moderation{" "}
        </h2>
        <p className="font-DMsansRegular mb-2">
          We reserve the right to moderate and remove content that violates
          these Terms or is in violation of any applicable laws. We are not
          responsible for content posted by users and do not endorse or
          guarantee the accuracy of user-generated content.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">6. Privacy</h2>
        <p className="font-DMsansRegular mb-2">
          Your use of the Website is also governed by our Privacy Policy, which
          outlines our practices concerning the collection, use, and protection
          of your personal information. Please review our Privacy Policy to
          understand how we handle your data.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">
          7. Additional Terms on Monetization of User Content
        </h2>
        <p className="font-DMsansRegular mb-2">
          If you choose to monetize your content on our platform, you agree to
          adhere to our separate Monetization Policy, which outlines specific
          terms and conditions related to earning revenue through your content.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">8. Restrictions </h2>
        <p className="font-DMsansRegular mb-2">
          • Content Restrictions. You are prohibited from submitting any content
          that: • Infringes any third party’s Intellectual Rights including the
          copyrights or other rights of, such as trademark or privacy rights. •
          Encourages or Represents self-harm or suicide. • Advocates for or
          supports terrorist or hate groups. • Engages in harassment or abusive
          behavior. • Promotes fraudulent or questionable money-making schemes,
          suggests illegal transactions, or employs deceptive marketing tactics.
          • Provides instructions on creating explosives, incendiary devices,
          homemade firearms, or improvised weapons. • Exploits or poses a danger
          to minors. • Is defamatory. • Contains false or misleading claims
          about vaccination safety or health-related information that could
          seriously harm individuals or the public. • Displays unlawful
          real-world acts of extreme violence, sexualized violence (including
          rape, torture, abuse, and humiliation), or cruelty to animals, vivid
          and graphic acts of violence, viciousness and brutality. • Contains
          explicit sexual content or promotes sexual services. • contains false
          or misleading information. • Includes speech that is hateful or
          discriminatory. • Spreads claims that a real-world tragedy did not
          occur, false reports of violent crimes or disasters, or false and
          misleading information (including fake news, deep fakes, propaganda,
          or unverified and debunked conspiracy theories) that poses a
          significant risk of harm to individuals, groups, or the general
          public. • Violates any applicable law. • Use of Services Restrictions.
          While using our Services, you must refrain from: • Gathering personal
          information about others without their consent. • Publishing any form
          of "spam" or utilizing misleading metadata Engaging in deceptive
          behavior or impersonating any individual and/or entity. • Harassing or
          persistently stalking anyone. • Using or exporting any of our Services
          in a manner that violates the applicable laws. • Harming or taking
          advantage of minors. • Using inappropriate or offensive content (for
          instance, using explicit language) or using an icon/image/avatar that
          contains nudity. • Accessing someone else's account without their
          permission. • Encouraging or causing others to do any of the
          aforementioned actions. • Conduct any illegal activities.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">
          9. Respecting the Services Offered{" "}
        </h2>
        <p className="font-DMsansRegular mb-2">
          Users must respect and use our services only for their intended
          purposes. Any misuse, abuse, or violation of these terms may result in
          account suspension or termination.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">
          10. Representations and Warranties{" "}
        </h2>
        <p className="font-DMsansRegular mb-2">
          Each time you wish to submit, make available, and/or upload Content on
          the Website, you represent and warranty that: • You have the right and
          authority to submit such content to Telewee and to grant the licenses
          as described herein. • You have obtained necessary releases (if
          required) from all parties who appear in the content. • Telewee will
          not be required to secure licenses from any third party or pay any
          third-party royalties for displaying, streaming, or other allowed
          distribution of the content. • the content neither does nor will
          violate the rights of any third party, including intellectual property
          rights, rights of publicity, moral rights, and privacy rights. • The
          content is in compliance with these Terms and all relevant laws.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">
          11. No Infringement of IP Rights{" "}
        </h2>
        <p className="font-DMsansRegular mb-2">
          You may not post or share content that infringes upon the intellectual
          property rights of others. We will respond to valid infringement
          claims by removing or disabling access to the infringing content.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">12. Safety </h2>
        <p className="font-DMsansRegular mb-2">
          While using our services, you agree to abide by our Safety Guidelines
          to create a safe and respectful community for all users.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">
          13. Modification of Terms and Conditions{" "}
        </h2>
        <p className="font-DMsansRegular mb-2">
          We may update these Terms from time to time. We will notify you of any
          material changes, and the updated Terms will be posted on the Website
          with a revised "Effective Date." Your continued use of our services
          after the effective date of the revised Terms constitutes your
          acceptance of the changes.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">
          14. Termination and Suspension{" "}
        </h2>
        <p className="font-DMsansRegular mb-2">
          • These Terms shall come into force upon your use of our Services and
          shall remain valid as long as you use the Website and its Services or
          hold an account with Telewee, whichever is longer. We reserve the
          right to terminate or suspend your access to our services at our sole
          discretion, with or without cause, and without notice. • Upon
          termination or suspension for convenience or for whatsoever cause, you
          agree to: (i) Cease from using the Website and its Services and (ii)
          delete all materials, work, and information obtained as a result of
          using the Website and/or its Services, or at the request of Telewee,
          return all such materials, work, and information and (iii) pay all
          amounts owed to Telewee. If termination or suspension of your access
          to or use of the Website was due to a violation of any terms or
          reasonable cause, Telewee will not be obligated to refund any fees
          paid by you.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">15. Indemnity </h2>
        <p className="font-DMsansRegular mb-2">
          You agree to indemnify and hold Telewee and its affiliates harmless
          from any claims, damages, or losses arising from any breach by you
          and/or anyone acting on your behalf during the use of our services or
          any violation of these Terms.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">16. Disclaimer </h2>
        <p className="font-DMsansRegular mb-2">
          Our services provided "AS IS" and without representation, guarantee
          and warranty of any kind whether expressed or implied. Twelewee make
          no guarantees regarding to accuracy, reliability, or availability of
          our services. This includes any implied warranties of merchantability,
          Fitness for a particular purpose, title, or non-infringement, all of
          which we explicitly disclaim. We cannot assure that the website, our
          services, or any materials will fulfill your needs or that their use
          will be uninterrupted or error-free. The responsibility for the
          quality, performance and use of the website, our services and the
          materials lie with you.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">
          17. Limitation of Liability{" "}
        </h2>
        <p className="font-DMsansRegular mb-2">
          To the extent permitted by applicable law, TELWEE shall not be liable
          to you or any other party or entity for any general, indirect,
          incidental, special or exemplary damages, including damages for loss
          of business, profits, goodwill, data or other intangible losses, even
          if we have been advised of the possibility of such damages, loss and
          expense whether it was based by contract, infringement of intellectual
          property rights , or tort (including negligence and misconduct) or
          otherwise
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">
          18. Choice of Law and Alternative Dispute Resolution{" "}
        </h2>
        <p className="font-DMsansRegular mb-2">
          • These Terms are governed by and construed in accordance with the
          laws of the UAE. without regard to its conflict of laws principles.
          The United Nations Convention on Contracts for the International Sale
          of Goods does not govern these Terms. The rights granted herein, any
          upload or submission of Content, access to and use of the Website, and
          the reading and acceptance of these Terms will be deemed to take place
          in the Emirate of Sharjah in the United Arab Emirates. • Any dispute,
          difference, controversy, or claim arising out of or in connection with
          these Terms & Conditions, including (but not limited to) any question
          regarding its existence, validity, interpretation, performance,
          discharge, and applicable remedies, shall be subject to the exclusive
          jurisdiction of the Courts of Sharjah.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">19. Miscellaneous </h2>
        <p className="font-DMsansRegular mb-2">
          • You hereby Consent to electronic communication, and we may send you
          any notices to the email address and any other types of communication
          as provided by You in the Registered Website Account and the Personal
          Data Form and You agree that receiving any communications from us
          using such method is legally satisfactory to you. • The parties to
          these Terms are independent contractors, and nothing in these Terms or
          the rights granted herein, any upload or submission of Content or
          access to or use of the Website shall create a joint venture,
          partnership, employment relationship, or franchise or fiduciary
          relationship between the parties. • If any provision, or portion
          thereof, of this Agreement, or its application to any Person or
          circumstance, shall be invalid, illegal or unenforceable to any
          extent, the remainder provisions of this Agreement shall not be
          affected thereby, but shall be interpreted without such unenforceable
          provision or portion thereof so as to give effect, insofar as is
          possible, to the original intent of the parties, and shall otherwise
          be enforceable to the fullest extent permitted by law. • You
          acknowledge and consent that we have the authority to consider that
          all information used for signing up a Registered Account including
          your registered website username, or that of any such person who
          applies and completes a Personal Data Form or provides a user ID and
          password, as definitive proof of your intention to upload and submit
          Content and Content Information in accordance with the terms of these
          Terms Agreement. • These Terms shall be read with all terms and
          conditions available on the Website including the Content License
          Agreement, Content Partner Agreement, privacy policy, guidelines, and
          restrictions contained at the Website where you upload Content as
          modified by us from time to time in our sole discretion (collectively,
          the "Website Terms") and the Website. • All of the covenants,
          representation, warranties, and agreements contained in these Terms
          shall be binding upon, and inure to the benefit of, the parties hereto
          and, to the extent permitted by these Terms, their respective heirs,
          legal representatives, successors, and permitted assigns. • We reserve
          the right to transfer or assign the Terms & Conditions to any other
          party without requiring your consent, provided that such party agrees
          to be bound by its provisions. • Telewee will not be liable for any
          delay or failure caused by an event of force majeure such as: (a)acts
          of God/natural disasters (including hurricanes and earthquakes); (b)
          disease, epidemic, or pandemic; (c) terrorist attack, civil war, civil
          commotion or riots, armed conflict, sanctions or embargoes; (d)
          nuclear, chemical, or biological contamination; (e) collapse of
          buildings, fire, explosion, or accident; (f) labor or trade strikes;
          (g) interruption, loss, or malfunction of a utility, transportation,
          or telecommunications service; (h) any order by a government or public
          authority, including a quarantine, travel restriction, or other
          prohibition; or (i) any other circumstance not within Telewee
          reasonable control, whether or not foreseeable (each a “force majeure
          event”). In the event of a force majeure event, Telewee shall be
          relieved from full performance of the contractual obligation until the
          event passes or no longer prevents performance. • DISCLOSURE OF
          INFORMATION: We reserve the right to report any activity that either
          it suspects violates any Applicable Law to appropriate law enforcement
          officials, regulators, or other third parties. In order to cooperate
          with governmental requests, to protect Telewee.com, or to ensure the
          integrity and operation of Telewee.com business and systems,
          Telewee.com may access and disclose any information it considers
          necessary or appropriate, including but not limited to your account
          details, contact details, IP address and traffic information, usage
          history, and posted content.{" "}
        </p>








        <h2 className="text-xl font-DMsansSemiBold my-8">
          20. Rights Granted to the Website{" "}
        </h2>
        <p className="font-DMsansRegular mb-2">
        <span className="text-bold bold">a. Your content. </span>By posting or submitting content to the Website, you grant [Telewee] a license and permission to:

<div>
- stream, make the content available for download, and share your content with end users.
</div>
<div>

- promote and distribute the content through our Content License Agreement.
</div>
<div>
 
- alter, modify, develop, or omit any content and/or any part thereof.
</div>
<div>

 - Consent of uploading your videos on [Telewee], from your social media platforms (this consent is an optional choice and it can be disabled by removing the social media URL in your profile settings).
 </div>

 <div className="mt-8">
 b. Your feedback. You are welcome to share your comments, suggestions, or any other feedback regarding our Services. By doing so, you grant Telewee a non-exclusive, worldwide, royalty-free license to use irrevocable right, license, and permission to use, transmit, copy distribute, publicly present, share, and display that feedback in any way, without the obligation to provide any compensation to you on a perpetual basis. 
 </div>

 <div className="mt-8">
 All the licenses granted under this section are non-exclusive, worldwide, royalty-free licenses to use, reproduce, modify, adapt, and distribute the content for the purpose of operating and promoting the services. If you choose to monetize your content then you will be also subject to the terms and conditions of our Content Partner Agreement available on the Website. 
 </div>


 <h2 className="text-xl font-DMsansSemiBold my-8">
          21. Contact us{" "}
        </h2>

        <div>
        If you have any questions, concerns or need further clarification regarding the Website or these Terms & Conditions, please contact us at Email: Support@telewee.com
        </div>

        </p>
      </div>

      <div className="bg-palette-watermelon py-10 my-10 mb-20">
        <div className="flex lg:flex-row flex-col space-x-10 max-w-6xl mx-auto ">
          <div className="mx-auto lg:mx-0">
            <TermsQuestions className="h-[228px] w-[211.77px] lg:w-[263.79px] lg:h-[284px]" />
          </div>
          <div className="space-y-5">
            <h2 className="font-DMsansBold text-white text-2xl lg:text-3xl">
              If you have any questions, concerns or need further clarification
              regarding the Website or these Terms & Conditions
            </h2>
            <br />
            <h1 className="font-DMsansBold text-white text-3xl lg:text-4xl">
              please contact us at Email
            </h1>

            <button className="text-palette-watermelon bg-white rounded-lg px-12 lg:px-20 py-2 font-DMsansSemiBold">
              Support@telewee.com
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
