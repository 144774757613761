import Header from "../components/module/Landing/Header";
import TeleweeBlogImg from "../assets/TeleweeBlogImg.png";

export default function Landing() {
  const data = [
    {
      title: "What is & Why Telewee",
      date: "May 6, 2024",
      img: TeleweeBlogImg
    },
    {
      title: "Tips on creating video content that works",
      date: "April 29, 2024",
      img: "https://www.business2community.com/wp-content/uploads/2021/05/Untitled-design.png",
    },
    {
      title: "What is User Generated Content (UGC)?",
      date: "April 29, 2024",
      img: "https://billo.app/wp-content/uploads/2022/08/mateus-campos-felipe-ZKJQCWsKmPs-unsplash-scaled-1-1536x864.jpg"
    },
    {
      title: "How to monetize your content?",
      date: "April 29, 2024",
      img: "https://www.andromo.com/wp-content/uploads/2022/02/01-How-to-monetize-an-app-1024x576.jpg"
    },
    
  ]
  return (
    <div>
      <Header />
      <div class="bg-white py-24 sm:py-32">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto max-w-2xl text-center">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Telewee Blog Posts</h2>
            <p class="mt-2 text-lg leading-8 text-gray-600">Learn how to be a professional content creator.</p>
          </div>
          <div class="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {
              data.map((item, idx) => {
                return (
                  <article key={idx} class="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80">
                    <img src={item.img} alt="" class="absolute inset-0 -z-10 h-full w-full object-cover" />
                    <div class="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40"></div>
                    <div class="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>

                    <div class="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                      <time datetime="2020-03-16" class="mr-8">{item.date}</time>
                    </div>
                    <h3 class="mt-3 text-lg font-semibold leading-6 text-white">
                      <a href={`/blogs/${item.title}`}>
                        <span class="absolute inset-0"></span>
                        {item.title}
                      </a>
                    </h3>
                  </article>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}


