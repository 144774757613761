export const API_LOGIN = {
  url: "/users/login",
  method: "POST",
};

export const API_SIGNUP = {
  url: "/users",
  method: "POST",
};

export const API_EDIT_USER = {
  url: "/users",
  method: "PUT",
};

export const API_USER_DASHBOARD = {
  url: "/users",
  method: "GET",
};

export const API_ADD_VIDEO = {
  url: "/videos",
  method: "POST",
};



export const API_VERIFY_PHONE = {
  url: "/users/verify/account",
  method: "POST",
};

export const API_VERIFY_CODE = {
  url: "/users/verify",
  method: "POST",
};

export const API_UPDATE_PASSWORD = {
  url: "/users",
  method: "PUT",
};