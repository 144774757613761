import React, { useState } from "react";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


export default function PhoneField({
    value,
    onChange,
    placeholder,
    className
}) {


  return (
<PhoneInput
className={className}
  placeholder={placeholder}
  value={value}
  defaultCountry="JO"
  onChange={onChange}
  type="tel"
  id="phone"
  name="phone"
  autoComplete="tel"
  
  />

  );
}
