import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

import { ReactComponent as Upload } from "../../../assets/Upload.svg";
import { ReactComponent as GetApproved } from "../../../assets/GetApproved.svg";
import { ReactComponent as GetPaid } from "../../../assets/GetPaid.svg";

export default function HowItWorks() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t, i18n } = useTranslation();

  const [tabs, setTabs] = useState([
    {
      id: 1,
      name_en: "Upload",
      name_ar: "حمل",
      current: true,
    },
    {
      id: 2,
      name_en: "Get approved",
      name_ar: "احصل على الموافقة",
      current: false,
    },
    {
      id: 3,
      name_en: "Get paid",
      name_ar: "احصل على حصتك",
      current: false,
    },
  ]);

  function handle_tab_change(tab) {
    let updatedTabs = tabs.map((item, index) => {
      item.current = item.id === tab.id;
      return item;
    });
    setTabs(updatedTabs);
  }

  const selectedTab = tabs.filter((tab) => tab.current === true)[0];

  return (
    <div
      dir={currentLanguageCode === "ar" ? "rtl" : "ltr"}
      className="mt-20 lg:px-40 px-5"
    >
      <h1 className="text-palette-watermelon font-DMsansExtraBold text-center text-4xl ">
        {t("HowItWorks")}
      </h1>
      <nav className="flex -mb-px  mt-7">
        {tabs.map((tab, idx) => (
          <div
            onClick={() => handle_tab_change(tab)}
            key={idx}
            className={`${
              tab.current
                ? "border-palette-watermelon text-palette-watermelon font-DMsansSemiBold"
                : "border-[#FFE5E5]  hover:border-[#FFE5E5]"
            } cursor-pointer whitespace-nowrap py-2 w-full text-center border-b-4 font-DMsansRegular text-base lg:text-xl`}
          >
            {i18n.language === "en" ? tab.name_en : tab.name_ar}
          </div>
        ))}
      </nav>

      <div>
        {(i18n.language === "en" && selectedTab.name_en === t("Upload")) ||
        (i18n.language === "ar" && selectedTab.name_ar === t("Upload")) ? (
          <div className="grid grid-cols-1 lg:grid-cols-2 my-7 lg:my-20">
            <Upload className="mx-auto w-full px-5 lg:px-0 pt-5 lg:pt-0" />
            <div
              className={`my-auto order-first lg:order-last text-center ${
                currentLanguageCode === "ar" ? "lg:text-right" : "lg:text-left"
              } `}
            >
              <h2 className="font-DMsansBold text-2xl">{t("Upload")}</h2>
              <p className="font-DMsansRegular text-xl">{t("UploadDesc")}</p>
            </div>
          </div>
        ) : (i18n.language === "en" &&
            selectedTab.name_en === t("GetApproved")) ||
          (i18n.language === "ar" &&
            selectedTab.name_ar === t("GetApproved")) ? (
          <div className="grid grid-cols-1 lg:grid-cols-2 my-7 lg:my-20">
            <GetApproved className="mx-auto w-full px-5 lg:px-0 pt-5 lg:pt-0" />
            <div
              className={`my-auto order-first lg:order-last text-center ${
                currentLanguageCode === "ar" ? "lg:text-right" : "lg:text-left"
              } `}
            >
              <h2 className="font-DMsansBold text-2xl">{t("GetApproved")}</h2>
              <p className="font-DMsansRegular text-xl">
                {t("GetApprovedDesc")}
              </p>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 lg:grid-cols-2 my-7 lg:my-20">
            <GetPaid className="mx-auto w-full px-5 lg:px-0 pt-5 lg:pt-0" />
            <div
              className={`my-auto order-first lg:order-last text-center ${
                currentLanguageCode === "ar" ? "lg:text-right" : "lg:text-left"
              } `}
            >
              <h2 className="font-DMsansBold text-2xl">{t("GetPaid")}</h2>
              <p className="font-DMsansRegular text-xl">{t("GetPaidDesc")}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
