import { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";

import { ReactComponent as Dashboard } from "../../assets/dashboard/Dashboard.svg";
import { ReactComponent as Settings } from "../../assets/dashboard/Settings.svg";
import { ReactComponent as Logout } from "../../assets/dashboard/Logout.svg";
import { ReactComponent as Menu } from "../../assets/dashboard/Menu.svg";
import { ReactComponent as Logo } from "../../assets/Logo.svg";
import { ReactComponent as XCircle } from "../../assets/dashboard/XCircle.svg";
import LogoImg from "../../assets/dashboard/Logo.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t } = useTranslation();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigation = [
    { name: t("Dashboard"), href: "/dashboard", icon: Dashboard },
    { name: t("Settings"), href: "/settings", icon: Settings },
  ];

  async function logout() {
    localStorage.clear();
    window.location.replace("/");
  }

  return (
    <>
      <div>
        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              {/* <Logo
                className="mx-auto my-10 mb-14"
                onClick={() => navigate("/dashboard")}
              /> */}

              <img
                src={LogoImg}
                onClick={() => navigate("/landing")}
                className="w-[195px] h-[41px] object-cover my-auto cursor-pointer mx-auto my-10 mb-14"
              />

              <nav className="flex-1  space-y-4">
                {navigation.map((item, idx) => (
                  <div
                    key={idx}
                    onClick={() => navigate(item.href)}
                    className={classNames(
                      location.pathname === item.href ||
                        (location.pathname === "/" &&
                          item.href === "/dashboard")
                        ? "text-white bg-palette-watermelon"
                        : `text-black hover:bg-gray-100`,
                      "px-6 text-sm font-medium hover:cursor-pointer"
                    )}
                  >
                    <div className={`flex px-2 py-2`}>
                      <div className="flex grow">
                        <item.icon
                          className={classNames(
                            location.pathname === item.href ||
                              (location.pathname === "/" &&
                                item.href === "/dashboard")
                              ? "text-white bg-palette-watermelon"
                              : "text-palette-watermelon ",
                            "inline-flex mr-3 flex-shrink-0 h-6 w-6 "
                          )}
                        />
                        {item.name}
                      </div>
                    </div>
                  </div>
                ))}
              </nav>
              <div className="text-palette-watermelon mb-7  text-sm font-medium  hover:cursor-pointer">
                <div className={`flex px-4 py-2 hover:bg-gray-100 `}>
                  <div onClick={() => logout()} className="flex grow">
                    <Logout className="inline-flex mr-3 flex-shrink-0 h-6 w-6" />
                    {t("Logout")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="md:pl-64 flex flex-col flex-1 ">
          <div className="sticky top-0 z-10 md:hidden bg-gray-100 py-4 px-3">
            <div className="flex justify-between">
              <img
                onClick={() => navigate("/landing")}
                src={LogoImg}
                className="h-[33.64px] w-[160px] object-cover my-auto"
              />
              <button
                type="button"
                className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 outline-none"
                onClick={() => setSidebarOpen(!sidebarOpen)}
              >
                {sidebarOpen ? (
                  <XCircle className="h-8 w-8" aria-hidden="true" />
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-8 w-8">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
</svg>

                  // <Menu className="h-6 w-6" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
          {sidebarOpen ? (
            <div className="bg-white border-b">
              <div className="flex-1 pt-5 pb-4 overflow-y-auto">
                <nav className="mt-5  space-y-4 ">
                  {navigation.map((item) => (
                    <div
                      key={item.name}
                      onClick={() => navigate(item.href)}
                      className={classNames(
                        location.pathname === item.href ||
                          (location.pathname === "/" &&
                            item.href === "/dashboard")
                          ? "text-white bg-palette-watermelon"
                          : "text-black hover:bg-gray-100",
                        "group flex items-center py-2 text-base font-medium flex justify-center mx-auto"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          location.pathname === item.href ||
                            (location.pathname === "/" &&
                              item.href === "/dashboard")
                            ? "text-white bg-palette-watermelon"
                            : "text-palette-watermelon",
                          "mr-4 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </div>
                  ))}
                  <div
                    className={classNames(
                      "text-palette-watermelon group flex items-center py-2 text-base font-medium flex justify-center mx-auto"
                    )}
                    onClick={() => logout()}
                  >
                    <Logout
                      className={classNames(
                        "text-palette-watermelon mr-4 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {t("Logout")}
                  </div>
                </nav>
              </div>
            </div>
          ) : null}

          <main className="flex-1 bg-[#f9f9f9]">{props.children}</main>
        </div>
      </div>
    </>
  );
}
