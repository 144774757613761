import publicInstance from "../../utils/publicInstance";
import {
  API_LOGIN,
  API_SIGNUP,
  API_USER_DASHBOARD,
  API_EDIT_USER,
  API_ADD_VIDEO,
  API_VERIFY_PHONE,
  API_VERIFY_CODE,
  API_UPDATE_PASSWORD,
} from "../../constants/apis/User";


const loginAPI = async (data) => {
  const config = {
    ...API_LOGIN,
    data,
  };
  return await publicInstance(config)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e;
    });
};




const verifyPhoneAPI = async (data) => {
  const config = {
    ...API_VERIFY_PHONE,
    data,
  };
  return await publicInstance(config)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e;
    });
};



const verifyCodeAPI = async (data) => {
  const config = {
    ...API_VERIFY_CODE,
    data,
  };
  return await publicInstance(config)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e;
    });
};


const updatePasswordAPI = async (data) => {
  const config = {
    ...API_UPDATE_PASSWORD,
    data,
  };
  return await publicInstance(config)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e;
    });
};


const signupAPI = async (data) => {
  const config = {
    ...API_SIGNUP,
    data,
  };
  return await publicInstance(config)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e;
    });
};

const dashboardAPI = async (id) => {
  const config = {
    ...API_USER_DASHBOARD,
    url: `${API_USER_DASHBOARD.url}/${id}/videos`,
  };
  return await publicInstance(config)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      throw e;
    });
};

const editUserAPI = async (data) => {
  const config = {
    ...API_EDIT_USER,
    data,
  };
  return await publicInstance(config)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e;
    });
};

const addVideoAPI = async (data) => {
  const config = {
    ...API_ADD_VIDEO,
    data,
  };
  return await publicInstance(config)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e;
    });
};

export { loginAPI, signupAPI, dashboardAPI, editUserAPI, addVideoAPI,verifyPhoneAPI,verifyCodeAPI,updatePasswordAPI };
