// In src/pages/BlogPost.js

import React from 'react';
import { useParams } from 'react-router-dom';
import Header from "../components/module/Landing/Header";
import { useState } from "react";
import TeleweeBlogImg from "../assets/TeleweeBlogImg.png";

const BlogPost = () => {
    const { slug } = useParams();
    const [img, setImages] = useState({
        '1': "https://www.business2community.com/wp-content/uploads/2021/05/Untitled-design.png",
        '2': "https://billo.app/wp-content/uploads/2022/08/mateus-campos-felipe-ZKJQCWsKmPs-unsplash-scaled-1-1536x864.jpg",
        '3': "https://www.andromo.com/wp-content/uploads/2022/02/01-How-to-monetize-an-app-1024x576.jpg",
        '4': TeleweeBlogImg
    });

    // Fetch blog post data based on the slug, or handle it however necessary
    return (
        <div>
            <Header />

            {/* <h1>Blog Post: {slug}</h1> */}
            {
                slug == "Tips on creating video content that works" ?
                    <div class="bg-white px-6 py-10 lg:px-8">
                        <div class="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                            <p class="text-base font-semibold leading-7 text-palette-watermelon">Introducing</p>
                            <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{slug}</h1>
                            <p class="mt-6 text-xl leading-8">
                                In an era dominated by visual information, video content stands as a cornerstone of digital communication, whether it's for marketing, education, or entertainment. Videos have the power to captivate audiences, convey information quickly, and drive user engagement more effectively than any other medium. As the digital landscape becomes increasingly competitive, creating video content that not only captures attention but also achieves its intended purpose is crucial. This guide will explore essential tips for crafting video content that resonates with viewers and delivers real results.
                            </p>
                            <div class="mt-10 max-w-2xl">
                                {/* <p>
                            The power of UGC lies in its authenticity and trustworthiness; it is produced by unpaid contributors who are often genuine customers, making it highly relatable and influential to potential buyers. Unlike traditional marketing content, UGC is not crafted with a promotional tone, but rather emerges organically from user experiences and opinions. This not only helps in building brand credibility but also enhances customer engagement and loyalty.
                            </p>
                             */}
                                <h3 class="mt-6 text-lg  tracking-tight text-gray-900 sm:text-2xl">Tips on Creating Effective Video Content</h3>

                                <ul role="list" class="mt-8 max-w-xl space-y-8 text-gray-600">
                                    <li class="flex gap-x-3">
                                        <svg class="mt-1 h-5 w-5 flex-none text-palette-watermelon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                        </svg>
                                        <span><strong class="font-semibold text-gray-900">Understand Your Audience.</strong>
                                            <br />Example: A skincare brand creates a series of short videos targeting teenagers struggling with acne. By addressing their specific concerns, the videos resonate more effectively.
                                            <br /><br />Tip: Conduct audience research to tailor your content to the preferences, needs, and viewing habits of your target demographic.
                                        </span>
                                    </li>
                                    <li class="flex gap-x-3">
                                        <svg class="mt-1 h-5 w-5 flex-none text-palette-watermelon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                        </svg>
                                        <span><strong class="font-semibold text-gray-900">Keep It Concise and Focused.</strong>


                                            <br />Example: A tech company releases a 90-second video explaining a complex product. By keeping the video short and using simple language, they ensure that the core message is clear and engaging.

                                            <br /><br />Tip: Aim for brevity. A focused message with a clear call to action tends to retain viewer interest better than a lengthy video.



                                        </span>
                                    </li>
                                    <li class="flex gap-x-3">
                                        <svg class="mt-1 h-5 w-5 flex-none text-palette-watermelon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                        </svg>
                                        <span><strong class="font-semibold text-gray-900">Emphasize Quality Over Quantity.</strong>



                                            <br />Example: A travel vlogger invests in good quality audio equipment, resulting in crystal-clear narration that enhances the overall viewing experience

                                            <br /><br />Tip: Invest in good filming equipment or use professional editing software to enhance the visual and audio quality of your videos


                                        </span>
                                    </li>

                                    <li class="flex gap-x-3">
                                        <svg class="mt-1 h-5 w-5 flex-none text-palette-watermelon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                        </svg>
                                        <span><strong class="font-semibold text-gray-900">Incorporate Storytelling.</strong>



                                            <br />Example: A non-profit organization shares a touching story of how their efforts have changed individual lives, using real-life testimonials and emotional appeals.


                                            <br /><br />Use storytelling to connect on an emotional level. A well-told story can make your content memorable and impactful.


                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <figure class="mt-16">
                                <img class="aspect-video rounded-xl bg-gray-50 object-cover" src={img['1']} alt="" />
                            </figure>
                            <div class="mt-16 max-w-2xl">
                                <h2 class="text-2xl font-bold tracking-tight text-gray-900">Conclusion</h2>
                                <p class="mt-6">
                                    Creating video content that works is an art that requires understanding your audience, focusing on quality, and engaging viewers with compelling storytelling. By following these tips, you can create videos that not only attract attention but also inspire action and foster a deeper connection with your audience. In the digital age, where video content dominates, the ability to craft impactful videos is more crucial than ever. Dive into video creation with these strategies, and watch your content come alive and achieve its goals.
                                </p>
                            </div>
                        </div>
                    </div>
                    : slug == "What is User Generated Content (UGC)" ?
                        <div class="bg-white px-6 py-10 lg:px-8">
                            <div class="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                                <p class="text-base font-semibold leading-7 text-palette-watermelon">Introducing</p>
                                <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{slug}</h1>
                                <p class="mt-6 text-xl leading-8">
                                    In today's digital landscape, User-Generated Content (UGC) has emerged as a transformative force in marketing, communication, and community building. UGC refers to any form of content—be it text, videos, images, reviews, etc.—created by users rather than brands, and is publicly available to other consumers. This content often appears on social media platforms, blogs, and other online spaces where user interaction is encouraged.
                                </p>
                                <div class="mt-10 max-w-2xl">
                                    <p>
                                        The power of UGC lies in its authenticity and trustworthiness; it is produced by unpaid contributors who are often genuine customers, making it highly relatable and influential to potential buyers. Unlike traditional marketing content, UGC is not crafted with a promotional tone, but rather emerges organically from user experiences and opinions. This not only helps in building brand credibility but also enhances customer engagement and loyalty.
                                    </p>

                                    <h3 class="mt-6 text-lg  tracking-tight text-gray-900 sm:text-2xl">Examples of User-Generated Content</h3>

                                    <ul role="list" class="mt-8 max-w-xl space-y-8 text-gray-600">
                                        <li class="flex gap-x-3">
                                            <svg class="mt-1 h-5 w-5 flex-none text-palette-watermelon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                            </svg>
                                            <span><strong class="font-semibold text-gray-900">Social Media Posts.</strong>Many brands encourage their users to post content with specific hashtags to showcase their products in real-world settings. For instance, a clothing brand might encourage its customers to post pictures of themselves wearing their clothes with a designated hashtag</span>
                                        </li>
                                        <li class="flex gap-x-3">
                                            <svg class="mt-1 h-5 w-5 flex-none text-palette-watermelon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                            </svg>
                                            <span><strong class="font-semibold text-gray-900">Reviews and Testimonials.</strong> Websites like Amazon, TripAdvisor, and Yelp thrive on user-generated reviews. These platforms allow users to detail their experiences with products or services, influencing the decisions of future customers</span>
                                        </li>
                                        <li class="flex gap-x-3">
                                            <svg class="mt-1 h-5 w-5 flex-none text-palette-watermelon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                            </svg>
                                            <span><strong class="font-semibold text-gray-900">Video Reviews and Tutorials.</strong> Platforms like YouTube are rich with user-generated tutorials and reviews. Users often demonstrate products, explain features, and share their honest feedback about their effectiveness</span>
                                        </li>

                                        <li class="flex gap-x-3">
                                            <svg class="mt-1 h-5 w-5 flex-none text-palette-watermelon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                            </svg>
                                            <span><strong class="font-semibold text-gray-900">Blogs and Articles.</strong> Many consumers maintain personal blogs or contribute guest posts to other websites, where they share their experiences with products or services, often in detailed narratives.</span>
                                        </li>
                                    </ul>
                                </div>
                                <figure class="mt-16">
                                    <img class="aspect-video rounded-xl bg-gray-50 object-cover" src={img['2']} alt="" />
                                </figure>
                                <div class="mt-16 max-w-2xl">
                                    <h2 class="text-2xl font-bold tracking-tight text-gray-900">Conclusion</h2>
                                    <p class="mt-6">
                                        User-Generated Content represents a shift towards more democratic forms of media production and consumption. It empowers consumers, giving them a voice in the marketplace while providing businesses with invaluable insights into customer experiences and preferences. For brands, embracing UGC can not only enhance authenticity and trust but also significantly amplify their marketing efforts without substantial investments. As digital platforms continue to evolve, the role of UGC will likely become more crucial in shaping the ways brands interact with their customers, making it an essential element of modern digital marketing strategies.
                                    </p>
                                </div>
                            </div>
                        </div>
                        :

                        slug == "What is & Why Telewee" ?
                            <div class="bg-white px-6 py-10 lg:px-8">
                                <div class="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                                    <p class="text-base font-semibold leading-7 text-palette-watermelon">Introducing</p>
                                    <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{slug}</h1>
                                    <p class="mt-6 text-xl leading-8">
                                        Telewee is a pioneering force in the realm of digital user-generated content distribution, specializing in the strategic dissemination of user-generated videos across diverse social media platforms. Our core focus lies in optimizing engagement, expanding reach, monetize and protecting copyrights and empowering businesses and content creators to enhance their digital footprint. Utilizing cutting-edge technology and advanced analytics, we tailor content distribution strategies to meet the unique goals and preferences of our clients. With a commitment to fostering meaningful connections and driving monetization, Telewee stands at the forefront of innovation in the dynamic landscape of content distribution, sharing monetized revenues and helping clients secure and maximize the value of their video assets for sustained success in the digital era.
                                    </p>
                                    <div class="mt-10 max-w-2xl">

                                        <h3 class="mt-6 text-lg  tracking-tight text-gray-900 sm:text-2xl">How Telewee Empowers Content Creators?</h3>

                                        <ul role="list" class="mt-8 max-w-xl space-y-8 text-gray-600">
                                            <li class="flex gap-x-3">
                                                <svg class="mt-1 h-5 w-5 flex-none text-palette-watermelon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                                </svg>
                                                <span><strong class="font-semibold text-gray-900">Supercharge engagement.</strong>See your videos explode with views, likes, and shares</span>
                                            </li>
                                            <li class="flex gap-x-3">
                                                <svg class="mt-1 h-5 w-5 flex-none text-palette-watermelon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                                </svg>
                                                <span><strong class="font-semibold text-gray-900">Expand your reach.</strong> Tap into new communities and build a loyal following</span>
                                            </li>
                                            <li class="flex gap-x-3">
                                                <svg class="mt-1 h-5 w-5 flex-none text-palette-watermelon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                                </svg>
                                                <span><strong class="font-semibold text-gray-900">Monetize your content.</strong> sharing monetized revenues, Secure partnerships, and ad placements</span>
                                            </li>

                                            <li class="flex gap-x-3">
                                                <svg class="mt-1 h-5 w-5 flex-none text-palette-watermelon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                                </svg>
                                                <span><strong class="font-semibold text-gray-900">Save time and resources.</strong> We handle the distribution and optimization, freeing you to focus on what you do best
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <figure class="mt-16">
                                        <img class="aspect-video rounded-xl bg-gray-50 object-cover" src={img['4']} alt="" />
                                    </figure>
                                    <div class="mt-16 max-w-2xl">
                                        <h2 class="text-2xl font-bold tracking-tight text-gray-900">How To Make Money By Using Telewee?</h2>
                                        <p class="mt-6">
                                        To start earning money and Unleashing the Power of your Videos you just have to Create an account & upload your videos.
We're not just a distribution platform, we're your strategic partner in maximizing the impact of user-generated video content (UGC). We bridge the gap between captivating UGC and impactful social media presence across platforms like Facebook, Instagram, TikTok, and YouTube.

                                        </p>
                                    </div>


                                    <ul role="list" class="mt-8 max-w-xl space-y-8 text-gray-600">
                                            <li class="flex gap-x-3">
                                                <svg class="mt-1 h-5 w-5 flex-none text-palette-watermelon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                                </svg>
                                                <span><strong class="font-semibold text-gray-900">Content ninjas.</strong>We tailor your UGC to each platform's algorithms and audiences, boosting engagement and reach</span>
                                            </li>
                                            <li class="flex gap-x-3">
                                                <svg class="mt-1 h-5 w-5 flex-none text-palette-watermelon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                                </svg>
                                                <span><strong class="font-semibold text-gray-900">Data-driven decisions.</strong> We use insights to optimize strategy, ensuring your videos land in the right feeds and captivate the right viewers</span>
                                            </li>
                                            <li class="flex gap-x-3">
                                                <svg class="mt-1 h-5 w-5 flex-none text-palette-watermelon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                                </svg>
                                                <span><strong class="font-semibold text-gray-900">Monetization unlocked.</strong>We help you turn your UGC into revenue through partnerships, and ad placements and sharing the revenues between us</span>
                                            </li>

                                            <li class="flex gap-x-3">
                                                <svg class="mt-1 h-5 w-5 flex-none text-palette-watermelon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                                </svg>
                                                <span><strong class="font-semibold text-gray-900">Your time is ours.</strong> Focus on your core strengths while we handle the distribution and optimization heavy lifting
                                                </span>
                                            </li>
                                        </ul>
                                </div>
                            </div> :

                            <div class="bg-white px-6 py-10 lg:px-8">
                                <div class="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                                    <p class="text-base font-semibold leading-7 text-palette-watermelon">Introducing</p>
                                    <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{slug}</h1>
                                    <p class="mt-6 text-xl leading-8">
                                        In today’s digital landscape, video content reigns supreme, offering content creators unprecedented opportunities to engage with audiences and generate revenue. From vloggers to professional filmmakers, individuals are finding that video content isn't just a powerful tool for storytelling and marketing—it’s also a viable source of income. With platforms like YouTube, Vimeo, and TikTok dominating social media consumption, understanding how to monetize video content effectively can transform a hobby into a lucrative business. This guide will delve into practical strategies to help you monetize your video content, ensuring that your creative efforts yield financial rewards.
                                    </p>
                                    <div class="mt-10 max-w-2xl">

                                        <h3 class="mt-6 text-lg  tracking-tight text-gray-900 sm:text-2xl">Steps and Advice on Monetizing Video Content</h3>

                                        <ul role="list" class="mt-8 max-w-xl space-y-8 text-gray-600">
                                            <li class="flex gap-x-3">
                                                <svg class="mt-1 h-5 w-5 flex-none text-palette-watermelon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                                </svg>
                                                <span><strong class="font-semibold text-gray-900">Choose the Right Platform.</strong>Select a platform that aligns with your content style and audience. YouTube is great for long-form content and has a robust monetization system through ads, while TikTok favors short, engaging clips ideal for viral growth</span>
                                            </li>
                                            <li class="flex gap-x-3">
                                                <svg class="mt-1 h-5 w-5 flex-none text-palette-watermelon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                                </svg>
                                                <span><strong class="font-semibold text-gray-900">Leverage Advertising.</strong> Sign up for advertising programs like YouTube’s Partner Program, which allows you to earn money from ads placed on your videos</span>
                                            </li>
                                            <li class="flex gap-x-3">
                                                <svg class="mt-1 h-5 w-5 flex-none text-palette-watermelon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                                </svg>
                                                <span><strong class="font-semibold text-gray-900">Incorporate Sponsored Content.</strong> Partner with brands that resonate with your audience to create sponsored videos</span>
                                            </li>

                                            <li class="flex gap-x-3">
                                                <svg class="mt-1 h-5 w-5 flex-none text-palette-watermelon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                                                </svg>
                                                <span><strong class="font-semibold text-gray-900">Offer Exclusive Content.</strong> Use platforms like Telewee to offer exclusive content for a subscription fee.
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <figure class="mt-16">
                                        <img class="aspect-video rounded-xl bg-gray-50 object-cover" src={img['3']} alt="" />
                                    </figure>
                                    <div class="mt-16 max-w-2xl">
                                        <h2 class="text-2xl font-bold tracking-tight text-gray-900">Conclusion</h2>
                                        <p class="mt-6">
                                            Monetizing video content requires a strategic approach that combines understanding your audience, leveraging the right monetization tools, and maintaining the quality and authenticity of your content. By following these steps and continuously engaging with your viewers, you can build a sustainable revenue stream that supports your creative endeavors. Remember, the key to successful monetization is not just in attracting viewers but in maintaining a loyal community that values your work and is willing to support it financially. Start implementing these strategies today, and watch your video content turn into a profitable asset.
                                        </p>
                                    </div>
                                </div>
                            </div>
            }


        </div>
    );
}

export default BlogPost;
