import { useRef } from "react"; // Importing useRef hook
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Footer from "../components/module/Landing/Footer";
import Header from "../components/module/Landing/Header";
import { ReactComponent as ContactUsIcon } from "../assets/ContactUs.svg";
import { ReactComponent as EWbg } from "../assets/ew-bg-contact.svg";

export default function ContactUs() {
  const formRef = useRef(null); // Creating a ref for the form

  const handleSubmit = (e) => {
    e.preventDefault(); // Preventing default form submission

    toast.success("We have received your inquiry successfully.", {
      transition: Slide,
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    // Accessing the input values using formRef
    // const formData = new FormData(formRef.current);
    // const name = formData.get("name");
    // const email = formData.get("email");
    // const message = formData.get("message");

    // // Now you can do whatever you want with the data
    // console.log("Name:", name);
    // console.log("Email:", email);
    // console.log("Message:", message);

    // Optionally, you can reset the form
    formRef.current.reset();
  };

  return (
    <div>
      <Header />

      <div className="relative">
        <EWbg className="absolute left-0 -top-40 lg:top-6 w-[60%] lg:w-auto -z-10" />

        <div className="flex flex-col lg:flex-row space-y-10 space-x-0 lg:space-y-0 lg:space-x-10 px-5 lg:px-36 mt-20 mb-32 lg:mb-52">
          <div>
            <ContactUsIcon className="h-[258px] w-[324.79px] lg:h-[419.42px] lg:w-[528px] " />
          </div>

          <div>
            <h1 className="text-palette-watermelon font-DMsansExtraBold text-3xl lg:text-4xl">
              Contact Us
            </h1>
            <p className="mt-2 mb-5 text-base lg:text-lg max-w-4xl font-DMsansRegular">
              If you have any feedback, issues, or suggestions, please fill out
              the required fields below to get in touch with us.{" "}
            </p>

            <form ref={formRef} onSubmit={handleSubmit}>
              <div className="flex space-x-5 mb-4">
                <div className="grow">
                  <p className="font-DMsansBold">Name</p>
                  <input
                    name="name"
                    placeholder="Enter your name"
                    required
                    className="border-b border-gray-200 py-2 w-full outline-none"
                  />
                </div>
                <div className="grow">
                  <p className="font-DMsansBold">Email</p>
                  <input
                    name="email"
                    placeholder="Enter your email"
                    type="email"
                    required
                    className="border-b border-gray-200 py-2 w-full outline-none"
                  />
                </div>
              </div>

              <div>
                <p className="font-DMsansBold">Message</p>
                <textarea
                  name="message"
                  placeholder="Enter your message"
                  required
                  className="border-b border-gray-200 py-2 w-full outline-none"
                />
              </div>
              <button
                type="submit"
                className="bg-palette-watermelon w-full rounded-lg my-10 text-white py-2 font-DMsansSemiBold"
              >
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />

      <Footer />
    </div>
  );
}
