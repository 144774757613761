import { ReactComponent as TermsIcon } from "../assets/Terms.svg";
import Footer from "../components/module/Landing/Footer";
import Header from "../components/module/Landing/Header";

export default function Privacy() {
  return (
    <div>
      <Header />
      <div className="bg-[#FAFAFA]">
        <div className="max-w-6xl mx-auto">
          <div className="flex flex-col lg:flex-row space-x-7 py-10">
            <TermsIcon className="h-[218px] w-[264.42px] mx-auto lg:mx-0 lg:w-[402.7px] lg:h-[332px]" />
            <h1 className="text-5xl lg:text-7xl grow my-auto font-DMsansBold text-palette-watermelon tracking-widest mt-5 lg:mt-0">
              Privacy
              <br /> policy
            </h1>
          </div>
        </div>
      </div>

      <div className="max-w-6xl mx-auto my-10 px-5 lg:px-0">
        <p className="font-DMsansRegular mb-2">
          Please read this policy and reach out to us with any inquiries. By
          using or accessing our website, you agree to the collection,
          utilization, processing, and sharing of your personal information/data
          in compliance with this policy. Your privacy is of utmost importance
          to us at Telewee. We are dedicated to ensuring the protection of your
          personal information and maintaining the highest standards of privacy.
          This Privacy Policy outlines how we collect, utilize, and safeguard
          your data when you engage with our website and services.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">
          Collection of Personal Information
        </h2>
        <p className="font-DMsansRegular mb-2">
          We collect information through various interactions with our platform,
          including the access or use of our website and services, registration
          of an account creation, video uploads, and any engagement with our
          features. The types of information we may collect include: a. Personal
          Information: - Your name - Email address - Username and password -
          Profile picture (if uploaded) - Payment details (if opting for premium
          features) To validate the personal information and/or if you are a
          user who is providing content according to our Content Partner
          Agreement and/or according to Content License, we will request the
          following: - A copy of your proof of identity (e.g., passport or
          driver’s license). - A copy of proof of address (e.g. utility bill).
          As such personal information will be collected based on the
          information provided by you in our Personal Data Form. b. Content
          Information: - Videos and other content uploaded user-generated to
          Telewee - Comments, messages, and other user-generated content c.
          Usage Information: - Device and browser specifics - Log data, such as
          IP address, access times, and referral URLs - Usage statistics and
          analytics Utilization of Your Information We utilize collected
          information/data to provide, maintain, and enhance our services,
          including: a. Facilitating the uploading and sharing of your content
          on different social media platforms. b. Communicating with you
          concerning your account, updates, and promotional activities. c.
          Enhancing our services and optimizing user experience. d. Addressing
          your queries and providing customer support. e. Analyzing user
          behavior and trends to improve our platform.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">
          Information Sharing
        </h2>
        <p className="font-DMsansRegular mb-2">
          Under specific circumstances, we may share your information: a. With
          Social Media Platforms: Sharing your video content and related
          information with the platforms where your content is published. b.
          With Service Providers: Engaging third-party service providers to
          assist us with various aspects of our services, such as hosting,
          payment processing, and analytics. c. Legal Compliance: Sharing
          information as required to comply with legal obligations, protect our
          rights, or respond to lawful requests from authorities. Your Privacy
          Choices You retain control over your personal information: a. Account
          Settings: You can update and edit your personal information through
          your account settings. In order for us to verify that any such request
          is really being made by you, we will need you to provide us with the
          following: - A copy of your proof of identity (e.g., passport or
          driver’s license). - A copy of proof of address (e.g. utility bill).
          b. Communications: You can manage your communication preferences,
          including opting out of promotional emails.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">Security Measures</h2>
        <p className="font-DMsansRegular mb-2">
          We prioritize the security of your personal information and implement
          reasonable measures to protect it. However, please note that no online
          platform can ensure absolute data security of your data.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">Children's Privacy</h2>
        <p className="font-DMsansRegular mb-2">
          Our services are not intended for users under 18 years old. We do not
          knowingly collect personal information from users under 18. If you
          believe we have collected such information, please contact us, and we
          will take appropriate steps to delete it.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">
          Changes to this Privacy Policy
        </h2>
        <p className="font-DMsansRegular mb-2">
          We may periodically update this Privacy Policy. Significant changes
          will be communicated through our website or other means. Your
          continued use of Telewee post-changes implies acceptance of the
          revised policy.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">Contact Us</h2>
        <p className="font-DMsansRegular mb-2">
          For queries about this Privacy Policy or your personal information,
          please contact us at support@telewee.com. Thank you for using Telewee!
          We are committed to providing you with a secure and enjoyable platform
          for content creators.
        </p>

        <h2 className="text-xl font-DMsansSemiBold mb-2">Data Deletion</h2>
        <p className="font-DMsansRegular mb-2">
          Any user at anytime can reach out to support@telewee.com and send a request to remove part or full of their data and histroy.
        </p>
      </div>

      <Footer />
    </div>
  );
}
